//@ts-check
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleSheet,
  View,
  Text,
  Image,
  FlatList,
  TouchableOpacity,
  Platform,
  InteractionManager,
} from 'react-native';
import { mScale, normalizeInput, convertMinToHrMin } from '../utils/myUtils';
import InputBox from '../components/UI/forms/InputBox';
import ServiceList from '../components/UI/ServiceList';

import { Picker } from '@react-native-picker/picker';
import { useForm } from 'react-hook-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalStyles } from '../styles/global';
import CheckInNotAvailable from '../components/UI/CheckInNotAvailable';

const ServicesScreen = (props) => {
  console.log('ServicesScreen props', props);
  const propsObj = props.route.params;
  const [selected, setSelected] = React.useState(-1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    console.log('ServicesScreen Props CHANGED!!!', props);
  }, [props]);

  const selectItem = (item) => {
    // setIsDisabled(true);
    setSelectedItem(item);

    InteractionManager.runAfterInteractions(() => {
      console.log('setSelectedItem1', item);
      // setTimeout(function () {
      // console.log('setSelectedItem2', item);
      // if (propsObj.resetForm !== undefined && !propsObj.resetForm)
      //   props.navigation.goBack();
      // else {
      console.log('props.navigation.navigate', item);
      props.navigation.push('Home', {
        selectedService: item,
        ...propsObj,
        resetForm: propsObj.resetForm !== undefined ? propsObj.resetForm : true,
      });
      // }
      // }, 0);
    });

    // props.navigation.goBack({
    //   params: { selectedGuest: item },
    // });
  };

  if (!propsObj.isCheckInAvailable) {
    return <CheckInNotAvailable />;
  }

  if (!propsObj.queueSettings) {
    return (
      <View style={globalStyles.container}>
        <View style={{ marginTop: 40, alignItems: 'center' }}>
          <Image
            style={globalStyles.icon}
            /* @ts-ignore */
            source={require('../assets/logo.png')}
          />
          <Text style={globalStyles.title}>Invalid parameters</Text>
          <Text style={globalStyles.infoText2}>
            Please check the URL and try again.
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={globalStyles.container}>
      <View
        style={{
          margin: mScale(30),
          alignItems: 'center',
          justifyContent: 'center',
          // backgroundColor: 'red',
        }}
      >
        <Image
          style={globalStyles.icon}
          /* @ts-ignore */
          source={require('../assets/logo.png')}
        />
        <Text style={globalStyles.title}>Welcome to</Text>
        <Text style={globalStyles.title}>{propsObj.companyName}</Text>
      </View>
      <View
        style={{
          marginTop: mScale(10),
          // marginBottom: mScale(5),
          alignItems: 'center',
        }}
      >
        <Text style={globalStyles.title}>Reason for your visit:</Text>
      </View>
      <View
        style={{
          //   flex: 1,
          width: '100%',
          //   height: '100%',
          // backgroundColor: 'red',
          justifyContent: 'flex-start',
          marginVertical: mScale(15),
          //   alignItems: 'center',
          //   backgroundColor: appConst.DEF_BACKGROUND_COLOR,
        }}
      >
        <ServiceList
          serviceList={propsObj.services}
          selectItem={selectItem}
          isDisabled={isDisabled}
        />
      </View>
    </View>
  );
};

export default ServicesScreen;
