//@ts-check
if (!__DEV__) {
  console.log = () => null;
}

import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';

import {
  StyleSheet,
  Text,
  View,
  Platform,
  Dimensions,
  Image,
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from './screens/MainScreen';
import ServicesScreen from './screens/ServicesScreen';
import NotifyMeScreen from './screens/NotifyMeScreen';
import ConfirmationScreen from './screens/ConfirmationScreen';
import CancelledScreen from './screens/CancelledScreen';
import * as Font from 'expo-font';
// import { AppLoading } from 'expo';
import AppLoading from 'expo-app-loading';
import { Provider } from 'react-redux';
import { store, persistor } from './redux_store/store';
import { PersistGate } from 'redux-persist/integration/react';
import Colors from './constants/colors';
import Amplify, { Auth } from 'aws-amplify';
import config from './aws-exports';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSelector, useDispatch } from 'react-redux';
import * as guestActions from './redux_store/actions/guest';
import { get } from 'react-hook-form';
import { mScale, toISOLocal, validateInput } from './utils/myUtils';
import CheckInNotAvailable from './components/UI/CheckInNotAvailable';

Amplify.configure({
  ...config,

  Analytics: {
    disabled: true,
  },
  // graphql_headers: async () => {
  //   const currentSession = await Auth.currentSession();
  //   return { Authorization: currentSession.getIdToken().getJwtToken() };
  // },
});
let defaultScreen = 'Home';
let defaultParams = {};

const Stack = createStackNavigator();
// let paramObj = {};

const deviceDisplay = Dimensions.get('window');
const deviceHeight = deviceDisplay.height;
const deviceWidth = 800; //deviceDisplay.width;

export default function AppWrapper() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

const getCheckInAvailability = (startTime = '09:00', endTime = '16:30') => {
  return new Promise(async (resolve, reject) => {
    // var startTime = '15:10:10';
    // var endTime = '22:30:00';

    const currentDate = new Date();

    let startDate = new Date(currentDate.getTime());
    startDate.setHours(startTime.split(':')[0]);
    startDate.setMinutes(startTime.split(':')[1]);
    // startDate.setSeconds(startTime.split(':')[2]);

    let endDate = new Date(currentDate.getTime());
    endDate.setHours(endTime.split(':')[0]);
    endDate.setMinutes(endTime.split(':')[1]);
    // endDate.setSeconds(endTime.split(':')[2]);

    const isValid = startDate < currentDate && endDate > currentDate;
    console.log(
      'getCheckInAvailability',
      isValid,
      startDate,
      endDate,
      currentDate
    );
    resolve(isValid);
    return isValid;
  });
};

function App() {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [serviceStatus, setServiceStatus] = useState(false);
  const [isCheckInAvailable, setIsCheckInAvailable] = useState(true);
  const currentGuest = useSelector((state) => state.guestReducer?.currentGuest);
  console.log('App currentGuest', currentGuest);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(guestActions.checkGuestInfoLocally()).then((currentGuest) => {
      console.log('useEffect currentGuest 1', currentGuest);
      const dateStamp = toISOLocal(new Date()).slice(0, 10);
      if (
        // Object.keys(currentGuest).length > 0 &&
        currentGuest.ticketNumber?.length > 0 &&
        currentGuest.dateStamp === dateStamp
      ) {
        defaultScreen = 'Confirmation';
      }

      defaultParams = {
        ...defaultParams,
        ...currentGuest,
      };
      console.log('useEffect currentGuest 1 -->>', defaultParams);
    });
  }, []);
  //http://localhost:19007/?code=pcs&ver=v1&resource=newguest
  const getUrlParameters = () => {
    if (Platform.OS === 'web') {
      // paramObj.companyId = getParameterByName('companyId'); //0c90de62-71c6-45e4-8e66-417abfd55e0a
      defaultParams.companyCode = getParameterByName('code'); // pcs
      // paramObj.companyName = getParameterByName('companyName'); //Plastic Card Systems
      // paramObj.serviceId = getParameterByName('serviceId'); //A
      // paramObj.service = getParameterByName('service'); //renewal, registration, new appplication
      defaultParams.version = getParameterByName('ver');
      // paramObj.resource = getParameterByName('resource');
      // paramObj.resource = getParameterByName('groupAllowed');
    }

    // if (__DEV__ && !paramObj.companyCode) {
    //   // paramObj.companyId = '0c90de62-71c6-45e4-8e66-417abfd55e0a';
    //   // paramObj.companyName = 'Plastic Card Systems';
    //   paramObj.companyCode = 'pcs';
    //   // paramObj.serviceId = '';
    //   // paramObj.service = '';
    //   paramObj.version = 'v1';
    //   // paramObj.resource = 'newguest';
    //   // paramObj.isGroupCheckInAllowed = true;
    // }

    console.log('getUrlParameters', defaultParams);
  };
  const getServiceStatus = async () => {
    return new Promise(async (resolve, reject) => {
      getUrlParameters();

      const guestInfo = {
        method: 'getServiceStatus',
        serviceId: 1,
        companyCode: defaultParams.companyCode,
      };

      const url =
        'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/' +
        defaultParams.version +
        '/newguest';
      // console.log('url', guestInfo, url, props.route.params);
      // const data = guestInfo;
      const params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(guestInfo),
        method: 'POST',
        // mode: 'no-cors',
      };
      console.log('params', params, url);

      try {
        const apiResponse = await fetch(url, params);
        // console.log('apiResponse', apiResponse);

        if (apiResponse && apiResponse.ok) {
          console.log('response1 ----> ', apiResponse);
          const json = await apiResponse.json();
          console.log('response2 ----> ', json);
          // paramObj = {
          //   ...paramObj,
          //   ...json,
          // };
          // paramObj.companyName = json.companyName;
          // paramObj.companyId = json.id;
          // paramObj.queueSettings = json.queueSettings;
          // paramObj.queueInfo = json.queueInfo;

          resolve(json);
        } else {
          console.log('response NOT OK', apiResponse);
          reject(apiResponse);
        }
      } catch (error) {
        console.log('error', error);
        reject(error);
      }
      // setFieldList(fieldListData);
    });
  };

  const fetchFonts = async () => {
    // const paramObj2 =
    console.log('defaultScreen 1', defaultScreen);
    try {
      // await dispatch(guestActions.checkGuestInfoLocally()).then(
      //   (currentGuest) => {
      //     console.log('useEffect currentGuest 2', currentGuest);
      //     if (
      //       Object.keys(currentGuest).length > 0 &&
      //       currentGuest.ticketNumber?.length > 0
      //     ) {
      //       defaultScreen = 'Confirmation';
      //       defaultParams = currentGuest;
      //       console.log('useEffect currentGuest 2 -->>', currentGuest);
      //     }
      //   }
      // );

      // if (defaultScreen !== 'Confirmation')
      const serviceStatusObj = await getServiceStatus();
      defaultParams = {
        ...defaultParams,
        ...serviceStatusObj,
      };

      // const isOpen = await getCheckInAvailability(
      //   defaultParams.queueSettings.timeStartsAt,
      //   defaultParams.queueSettings.timeEndstsAt
      // );
      console.log(
        'defaultParams.isCheckInAvailable',
        defaultParams.isCheckInAvailable
      );
      setIsCheckInAvailable(defaultParams.isCheckInAvailable);

      // console.log('paramObj 2', paramObj2);
    } catch (error) {}

    return Font.loadAsync({
      'poppins-extralight': require('./assets/fonts/Poppins-ExtraLight.ttf'),
      'poppins-light': require('./assets/fonts/Poppins-Light.ttf'),
      'poppins-medium': require('./assets/fonts/Poppins-Medium.ttf'),
      'poppins-regular': require('./assets/fonts/Poppins-Regular.ttf'),
      'poppins-semibold': require('./assets/fonts/Poppins-SemiBold.ttf'),
    });
  };

  if (!fontLoaded) {
    return (
      <AppLoading
        startAsync={fetchFonts}
        onFinish={() => {
          setFontLoaded(true);
        }}
        onError={console.warn}
      />
    );
  }

  if (!isCheckInAvailable) {
    return <CheckInNotAvailable />;
  }

  return (
    <View style={styles.outerContainer}>
      <View style={styles.innerContainer}>
        {/* <Provider store={store}> */}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <NavigationContainer
            // style={{ backgroundColor: Colors.defBackgroundColor }}
            >
              {/* {console.log('defaultScreen', defaultScreen)} */}
              <Stack.Navigator
                initialRouteName={
                  defaultScreen === 'Confirmation'
                    ? defaultScreen
                    : defaultParams.isMultiService
                    ? 'Services'
                    : 'Home'
                }
              >
                <Stack.Screen
                  name='Services'
                  component={ServicesScreen}
                  options={{ headerShown: false }}
                  initialParams={defaultParams}
                  // style={{ backgroundColor: 'red' }}
                />
                <Stack.Screen
                  name='Home'
                  component={HomeScreen}
                  options={{ headerShown: false }}
                  // initialParams={paramObj}
                  initialParams={defaultParams}
                  // style={{ backgroundColor: 'red' }}
                />
                <Stack.Screen
                  name='NotifyMe'
                  component={NotifyMeScreen}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name='Confirmation'
                  component={ConfirmationScreen}
                  initialParams={defaultParams}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name='Cancelled'
                  component={CancelledScreen}
                  options={{ headerShown: false }}
                />
              </Stack.Navigator>
            </NavigationContainer>
          </PersistGate>
        </Provider>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: Colors.defBackgroundColor,
  },
  innerContainer: {
    flex: 1,
    width: '100%',
    maxWidth: deviceWidth,
    height: '100%',
    alignSelf: 'center',
    backgroundColor: 'red',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container2: {
    flex: 1,
    backgroundColor: '#F5F5F5',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 18,
    // alignSelf: 'center'
  },
  closed: {
    marginTop: 40,
    alignItems: 'center',
    width: '100%',
    // backgroundColor: 'red',
  },
  image: {
    width: 300,
    height: 180,
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginBottom: 10,
    // backgroundColor: 'red',
  },
  title: {
    fontSize: mScale(32),
    textAlign: 'center',
    // fontWeight: 'bold',
    color: 'black',
    fontFamily: 'poppins-regular',
    // width: '100%',
    // marginBottom: mScale(-50),
  },
  infoText2: {
    fontSize: mScale(22),
    fontFamily: 'poppins-light',
    // marginBottom: -2,
    // marginRight: 10,
    color: 'black',
    textAlign: 'center',
    width: '100%',
  },
});

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
