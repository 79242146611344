import AsyncStorage from '@react-native-async-storage/async-storage';
// import Reactotron from 'reactotron-react-native';
// import { API, graphqlOperation, AUTH_MODE } from 'aws-amplify';

import { useSelector, useDispatch } from 'react-redux';

import {
  listAssets,
  // listActivationCodes,
  getSaniSignRegInfo,
  batchGetAssets,
} from '../src/graphql/queries';

// export const getRegInfo = (activationCode) => {
//   return async (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         // await cleanActivationCodesinAWS();
//         console.log('connectionTimeout-STARTED');
//         let connectionTimeout = setTimeout(function () {
//           reject('Connection timed out. Try again later.');
//           return;
//         }, 20000);

//         console.log('activationCode', activationCode);
//         const regInfoFromGraphQl = await API.graphql(
//           graphqlOperation(getSaniSignRegInfo, {
//             code: activationCode,
//             authMode: 'AWS_IAM', //"AMAZON_COGNITO_USER_POOLS",
//           })
//         );
//         clearTimeout(connectionTimeout);
//         console.log('connectionTimeout-CLEARED=');
//         console.log('regInfoFromGraphQl', regInfoFromGraphQl);

//         const result = regInfoFromGraphQl.data?.getSaniSignRegInfo;

//         console.log('regInfo.status', result.status);
//         // console.log('regInfo.companyInfo1', result.regInfo.companyInfo);
//         // console.log(
//         //   'regInfo.companyInfo2',
//         //   JSON.parse(result.regInfo.companyInfo)
//         // );
//         // console.log(
//         //   'regInfo.companyInfo3',
//         //   JSON.parse(JSON.stringify(result.regInfo.companyInfo))
//         // );
//         console.log('regInfo', result);

//         if (result.status === 'INVALID')
//           reject('Code is invalid or has expired.');
//         else if (result.status === 'EXPIRED') reject('Code has expired!');
//         else if (result.status === 'MAXIMUM_DEVICE_REACHED')
//           reject('Maximum number of devices reached!');
//         else if (result.status === 'SUCCESS')
//           resolve({
//             registrationKey: result.regInfo.registrationKey,
//             companyInfo: JSON.parse(result.regInfo.companyInfo),
//             companyId: result.regInfo.companyId,
//             companyName: result.regInfo.companyName,
//             userName: result.regInfo.userName,
//           });
//         else {
//           reject(result.status);
//         }
//       } catch (err) {
//         console.log(err);
//         console.log('error fetching assets');
//         reject(err);
//       }
//     });
//   };
// };

const navigateToQueueList = (props, localRegInfoObj) => {
  console.log('navigateToQueueList');
  props.navigation.navigate('QueueListDrawer', {
    screen: 'QueueListStack',
    params: {
      regInfo: localRegInfoObj,
    },
  });
};

export const resetNavigation = (
  props,
  isMultiService,
  resetForm = true,
  isCheckInAvailable = true
) => {
  console.log('resetNavigation', props, isMultiService);
  if (isMultiService) {
    // props.navigation.push('Services', { resetForm: true });
    props.navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Services',
          params: {
            ...props,
            isCheckInAvailable: isCheckInAvailable,
            resetForm: resetForm,
          },
        },
      ],
    });
  } else {
    // props.navigation.push('Home', { resetForm: true });
    props.navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Home',
          params: {
            ...props,
            isCheckInAvailable: isCheckInAvailable,
            resetForm: resetForm,
          },
        },
      ],
    });
  }
};

const navigateToActivationCodeScreen = (props, screenName) => {
  props.navigation.navigate('ActivationCodeDrawer', {
    screen: screenName,
    params: { deactivateConfirmed: false },
  });
};

export const checkDeviceActivation = async (
  props,
  dispatch,
  assets,
  assetsUri
) => {
  try {
    // const localDevInfo = await AsyncStorage.getItem('deviceInfo');
    // console.log('localDevInfo', localDevInfo);
    const localRegInfo = await AsyncStorage.getItem('registrationInfo');
    console.log('localRegInfo', localRegInfo);

    const localRegInfoObj =
      localRegInfo !== null ? JSON.parse(localRegInfo) : null;

    // const localDevInfoObj =
    //   localDevInfo != null ? JSON.parse(localDevInfo) : null;
    console.log('localDevInfoObj2', localRegInfoObj);
    // if (localDevInfoObj !== null && localRegInfoObj !== null) {
    if (localRegInfoObj !== null) {
      if (localRegInfoObj.user == null)
        navigateToActivationCodeScreen(props, 'DeviceRegFormStack');
      else navigateToQueueList(props, localRegInfoObj);
    } else {
      console.log("navigate('Activation Code')");
      navigateToActivationCodeScreen(props, 'ActivationCodeStack');
    }
  } catch (e) {
    // error reading value
  }
};
