import { API, graphqlOperation, AUTH_MODE } from 'aws-amplify';

export const subscribe = async (
  graphqlSubscription,
  EventToSubscribe,
  queryVariables,
  callbackFunction,
  retrying = 0
) => {
  console.log('subscribing in...', retrying, EventToSubscribe, queryVariables);
  // setTimeout(async (graphqlSubscription) => {
  const observable = API.graphql(
    graphqlOperation(EventToSubscribe, queryVariables)
  );
  console.log('observable', observable);
  graphqlSubscription = await observable.subscribe({
    next: (eventData) => {
      retrying = 0;
      console.log('Subscription eventData', eventData);
      callbackFunction(eventData);
    },
    error: (data) => {
      const { errors } = data;
      console.log('Subscription Error: ', data);
      if (
        errors &&
        (errors.find((error) => error.message === 'Network Error') ||
          errors.find((error) => error.message === 'Socket'))
      ) {
        if (retrying === 0) {
          retrying = 50;
        }
        this.subscribe(retrying * 2);
      }
    },
    complete: (data) => console.log('completed', data),
  });

  console.log('graphqlSubscription', graphqlSubscription);

  return graphqlSubscription;
  // }, retrying);
};

export const disconnectSubs = (subscription) => {
  console.log('disconnect subscription', subscription);
  if (subscription && typeof subscription.unsubscribe === 'function') {
    subscription.unsubscribe();
  }
};
