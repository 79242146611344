import React, { useEffect, useRef } from 'react';
import {
  View,
  Text,
  Platform,
  Image,
  Dimensions,
  ActivityIndicator,
  TouchableOpacity,
  TouchableHighlight,
  Pressable,
  SafeAreaView,
  ScrollView,
  InteractionManager,
  StyleSheet,
  FlatList,
  Animated,
  Easing,
} from 'react-native';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import appConst from '../../constants/appConst';
import { globalStyles } from '../../styles/global';
import Colors from '../../constants/colors';
import { mScale } from '../../utils/myUtils';
// import moment, { now, diff } from 'moment';
// import { CLEAR_GUEST } from '../../redux_store/actions/guests';

// import { formatPhoneNumber } from '../utils/myUtils';

// import { useSelector, useDispatch, getState } from 'react-redux';
// import Visitor from '../models/visitors';
// import Animated, { Easing } from 'react-native-reanimated';
// import { loop, mix, useValue } from 'react-native-redash';
// import Animated, {
//   useCode,
//   Easing,
//   interpolate,
// } from 'react-native-reanimated';

// import Animated, {
//   useCode,
//   Easing,
//   interpolate,
// } from 'react-native-reanimated';

// import { useValue, mix, loop } from 'react-native-redash';

// const { set, concat } = Animated;

const ServiceList = (props) => {
  console.log('ServiceList props', props);
  var [isPress, setIsPress] = React.useState(false);
  const [selected, setSelected] = React.useState(-1);

  const animatedOpacity = useRef(new Animated.Value(1)).current;

  // useEffect(() => {
  //   Animated.loop(
  //     Animated.sequence([
  //       Animated.timing(animatedOpacity, {
  //         toValue: 0,
  //         duration: 250,
  //         easing: Easing.cubic,
  //         useNativeDriver: true,
  //       }),
  //       Animated.timing(animatedOpacity, {
  //         toValue: 1,
  //         duration: 250,
  //         easing: Easing.cubic,
  //         useNativeDriver: true,
  //         //   delay: 400,
  //       }),
  //     ]),
  //     {
  //       iterations: -1,
  //     }
  //   ).start();
  // }, []);

  const onItemSelected = (item, index) => {
    // InteractionManager.runAfterInteractions(() => {
    setSelected(index);
    if (props.selectItem) {
      props.selectItem(item);
    }
    // });
  };

  return (
    <View style={styles.container}>
      {/* <Text>
        Open up App.jssss to start working on your app!
        {props.guestsList.length}
      </Text> */}

      <View
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          // backgroundColor: appConst.DEF_BACKGROUND_COLOR,
          backgroundColor: 'transparent',
          paddingVertical: 0,
        }}
      >
        <FlatList
          data={props.serviceList}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => {
            // console.log('mobilePhone', item.regValue);
            return (
              <TouchableOpacity
                disabled={props.isDisabled}
                // activeOpacity={0}
                // underlayColor='green'
                style={[
                  globalStyles.flatListItemRound,
                  {
                    backgroundColor: selected === index ? 'white' : 'green',
                  },
                ]}
                onPress={() => {
                  console.log('onPress');
                  onItemSelected(item, index);
                  // setIsPress(!isPress);
                }}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={[
                      globalStyles.buttonLabel,
                      { color: selected === index ? 'dimgray' : 'white' },
                    ]}
                    // style={{
                    //   fontSize: mScale(24),
                    //   marginLeft: 10,
                    //   alignSelf: 'center',
                    //   // fontWeight: 'bold',
                    //   color: selected === index ? 'dimgray' : 'white',
                    // }}
                  >
                    {/* {item.Body.ticketNumber} */}
                    {item.type}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          }}
        />
      </View>

      {/* <View
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          backgroundColor: '#011627',
          paddingTop: 50,
          paddingHorizontal: 20,
        }}
      >
        <FlatList
          data={props.guestsList}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => {
            console.log('guestInfo', item);
            console.log('index', index);
            let ticketNumber =
              item.ticketNumber.slice(0, 3) + '-' + item.ticketNumber.slice(3);
            return (
              <Animated.View
                style={{
                  flexDirection: 'row',
                  backgroundColor: item.assignedLocation ? 'green' : 'white',
                  marginVertical: 15,
                  marginHorizontal: 30,
                  borderColor: 'grey',
                  borderWidth: 2,
                  height: 180,
                  borderRadius: 90,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  opacity: 1, //item.assignedLocation ? animatedOpacity : 1,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontSize: 80,
                      // paddingHorizontal: 50,
                      marginLeft: 60,
                      color: item.assignedLocation ? 'white' : 'dimgray',
                    }}
                  >
                    {index + 1}
                    {'.  '}
                    {ticketNumber}
                  </Text>
                </View>
                {item.assignedLocation && (
                  <View style={{ flexDirection: 'row' }}>
                    <Animated.View
                      style={{
                        marginHorizontal: 30,
                        opacity: item.assignedLocation ? animatedOpacity : 1,
                      }}
                    >
                      <MaterialCommunityIcons
                        name='arrow-right'
                        size={80}
                        color={item.assignedLocation ? 'white' : 'dimgray'}
                      />
                    </Animated.View>
                    <Text
                      style={{
                        fontSize: 80,
                        // paddingHorizontal: 15,
                        alignSelf: 'center',
                        color: item.assignedLocation ? 'white' : 'dimgray',
                      }}
                    >
                      {item.assignedLocation}
                    </Text>
                  </View>
                )}
              </Animated.View>
            );
          }}
        />
      </View> */}
    </View>
  );
};

var styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: 'red',

    alignItems: 'center',
    justifyContent: 'center',
  },

  btnNormal: {
    backgroundColor: 'white',
    marginHorizontal: 20,
    marginVertical: 10,
    padding: 20,
    height: 120,
    borderRadius: 60,
    flexDirection: 'row',
  },
  btnPress: {
    backgroundColor: 'green',
    marginHorizontal: 20,
    marginVertical: 10,
    padding: 20,
    height: 120,
    borderRadius: 60,
    flexDirection: 'row',
  },
});
export default ServiceList;
