//import ASSETS from "../../data/dummy-data";
import {
  DELETE_USER,
  CREATE_USER,
  UPDATE_USER,
  UPDATE,
  UPDATE_REGISTRATION_INFO,
} from '../actions/users';
// import Asset from "../../models/assets";

const initialUserState = {
  currentUser: {},
  currentRegInfo: {},
  allUsers: [],
};

export default (state = initialUserState, action) => {
  // console.log('user-action', action);
  switch (action.type) {
    case UPDATE_USER:
      // console.log("action.userData", action.userData);
      return {
        currentUser: action.userData,
      };

    case UPDATE_REGISTRATION_INFO:
      console.log('action.registrationInfo', action.regInfo);
      return {
        currentRegInfo: action.regInfo,
      };
  }
  return state;
};
