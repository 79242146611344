//@ts-check
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { globalStyles as styles } from '../styles/global';

import { convertMinToHrMin, getNumPeopleAheadMsg } from '../utils/myUtils';
import { DialogBox } from '../components/UI/DialogBox';
import BlinkingView from '../components/UI/BlinkingView';
import {
  onUpdateWaitListByGuestId,
  onDeleteWaitListByGuestId,
  onUpdateGuestSequenceNumberByCustomId,
} from '../src/graphql/subscriptions'; //'../src/graphql/subscriptions';

import { subscribe, disconnectSubs } from '../utils/graphqlModules';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSelector, useDispatch } from 'react-redux';
import { mScale, toISOLocal } from '../utils/myUtils';
import * as guestActions from '../redux_store/actions/guest';

import * as myModule from '../utils/myModule';

let subsOnUpdateGuestSequenceNumber;
let subsOnUpdateWaitListByGuestId;
let subsOnDeleteWaitListByGuestId;

const ConfirmationScreen = (props) => {
  const params = props?.route?.params;
  console.log('ConfirmationScreen props', params);

  // @ts-ignore
  const currentGuest = useSelector((state) => state.guestReducer?.currentGuest);
  let currentGuestState = useRef(currentGuest);
  const [withNotif, setWithNotif] = useState(params?.withNotifConsent);
  const [minBeforeTurn, setMinBeforeTurn] = useState(
    params?.minBeforeTurn ? params?.minBeforeTurn : 15
  );

  const [estWaitTime, setEstWaitTime] = useState(
    currentGuest.queueInfo?.waitTime
      ? currentGuest.queueInfo.waitTime.estMedWaitTime
      : 1
  );
  const [peopleAheadMsg, setPeopleAheadMsg] = useState(
    currentGuest.peopleAheadMsg
  );
  const [guestPhoneNum, setGuestPhoneNum] = useState(
    currentGuest.formattedPhoneNum
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogShowing, setIsDialogShowing] = useState(false);
  // const [queueInfoColor, setQueueInfoColor] = useState('green');
  const [isBliking, setIsBliking] = useState(false);
  const dispatch = useDispatch();

  const hideDialogHandle = async () => {
    setIsDialogShowing(false);
  };

  const onCancelConfirmed = async () => {
    setIsLoading(true);
    setIsDialogShowing(false);
    unsubscribeGraphQL();

    const guestInfo = {
      method: 'removeFromWaitList',
      guestWaitListId: currentGuest.guestWaitListId,
      companyCode: currentGuest.companyCode,
      serviceId: params.selectedService?.code,
    };

    const url =
      'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/' +
      currentGuest.version +
      '/newguest';
    console.log('url', guestInfo, url, props.route.params);
    // const data = guestInfo;
    const apiParams = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(guestInfo),
      method: 'POST',
      // mode: 'no-cors',
    };
    console.log('params', apiParams, currentGuest);
    props.navigation.push('Cancelled', { ...params });
    //Delete local guestinfo data regardless
    // dispatch(guestActions.deleteGuest(currentGuest.guestWaitListId));
    dispatch(guestActions.deleteGuest());

    try {
      const apiResponse = await fetch(url, apiParams);

      if (apiResponse && apiResponse.ok) {
        // console.log('response ----> ', apiResponse);
        apiResponse.json().then((json) => {
          console.log('response ----> ', json);
          // dispatch(guestActions.deleteGuest());
        });
      } else {
        console.log('response NOT OK', apiResponse);
      }
    } catch (error) {
      console.log('apiResponse error', error);
    }
    setIsLoading(false);
  };

  // const cancel = async () => {
  //   setIsDialogShowing(true);
  //   onCancelConfirmed();
  // };

  const unsubscribeGraphQL = useCallback(async () => {
    console.log('unsubscribeGraphQL', subsOnUpdateWaitListByGuestId);

    disconnectSubs(subsOnUpdateGuestSequenceNumber);
    disconnectSubs(subsOnUpdateWaitListByGuestId);
    disconnectSubs(subsOnDeleteWaitListByGuestId);
  }, []);

  useEffect(() => {
    console.log('useEffect currentGuest', currentGuest);
    currentGuestState.current = currentGuest;
  }, [currentGuest]);

  useEffect(() => {
    // params.selectedService.code;
    // const serviceId =
    //   currentGuest.serviceId !== '' ? currentGuest.serviceId : '1';
    const serviceId =
      currentGuest.selectedService && currentGuest.selectedService?.code !== ''
        ? currentGuest.selectedService?.code
        : '1';
    const dateStamp = toISOLocal(new Date()).slice(0, 10);
    const id = dateStamp + ':' + currentGuest.companyCode + '-' + serviceId;
    console.log(
      'ConfirmationScreen useEffect',
      id,
      currentGuest,
      props?.route?.params
    );

    //Save guestRegInfo locally
    // AsyncStorage.setItem('regFormData', JSON.stringify(props?.route?.params));
    console.log('currentGuest', currentGuest);

    //Check if current guest is empty
    if (
      Object.keys(currentGuest).length === 0 ||
      currentGuest.dateStamp != dateStamp
    ) {
      // props.navigation.push('Home', { resetForm: true });
      myModule.resetNavigation(props, params.isMultiService);
      // if (params.isMultiService) {
      //   // props.navigation.push('Services', { resetForm: true });
      //   props.navigation.reset({
      //     index: 0,
      //     routes: [{ name: 'Services' }],
      //   });
      // } else {
      //   // props.navigation.push('Home', { resetForm: true });
      //   props.navigation.reset({
      //     index: 0,
      //     routes: [{ name: 'Home', params: { resetForm: true } }],
      //   });
      // }
      return;
    }

    const subscribeToAWSGraphQL = async () => {
      //*--------------------------*
      //subsOnUpdateWaitListByGuestId
      //*--------------------------*
      subsOnUpdateWaitListByGuestId = await subscribe(
        //graphqlSubscription
        subsOnUpdateWaitListByGuestId,
        //onEventToSubscribe
        onUpdateWaitListByGuestId,
        // queryVariables
        {
          id: currentGuest.guestWaitListId,
        },
        //callbackFunction
        async (result) => {
          console.log(
            'onUpdateWaitListByGuestId',
            result.value.data.onUpdateWaitListByGuestId
          );

          const updatedGuestInfo = {
            assignedLocation:
              result.value.data.onUpdateWaitListByGuestId.assignedLocation,
          };
          // setEstWaitTime(estWaitTime);
          setIsBliking(true);
          dispatch(guestActions.updateGuest(updatedGuestInfo));
        },
        0
      );

      //*--------------------------*
      //subsOnDeleteWaitListByGuestId
      //*--------------------------*
      subsOnDeleteWaitListByGuestId = await subscribe(
        //graphqlSubscription
        subsOnDeleteWaitListByGuestId,
        //onEventToSubscribe
        onDeleteWaitListByGuestId,
        // queryVariables
        {
          id: currentGuest.guestWaitListId,
        },
        //callbackFunction
        async (result) => {
          console.log(
            'onDeleteWaitListByGuestId',
            result.value.data.onDeleteWaitListByGuestId
          );
          unsubscribeGraphQL();
          myModule.resetNavigation(props, params.isMultiService);
          // if (params.isMultiService) {
          //   // props.navigation.push('Services', { resetForm: true });
          //   props.navigation.reset({
          //     index: 0,
          //     routes: [{ name: 'Services' }],
          //   });
          // } else {
          //   // props.navigation.push('Home', { resetForm: true });
          //   props.navigation.reset({
          //     index: 0,
          //     routes: [{ name: 'Home', params: { resetForm: true } }],
          //   });
          // }

          // props.navigation.push('Home', { resetForm: true });
          dispatch(guestActions.deleteGuest());
        },
        0
      );

      //*--------------------------*
      //subsOnUpdateGuestSequenceNumberByCustomId
      //*--------------------------*
      subsOnUpdateGuestSequenceNumber = await subscribe(
        //graphqlSubscription
        subsOnUpdateGuestSequenceNumber,
        //onEventToSubscribe
        onUpdateGuestSequenceNumberByCustomId,
        // queryVariables
        {
          id: id,
        },
        //callbackFunction
        async (updateGuestSequenceNumber) => {
          console.log(
            'updateGuestSequenceNumber',
            updateGuestSequenceNumber,
            currentGuestState.current
          );

          const sequenceInfo =
            updateGuestSequenceNumber.value.data
              .onUpdateGuestSequenceNumberByCustomId;
          const waitList = sequenceInfo.waitList.split(',');

          const queuePos = waitList.indexOf(
            currentGuestState.current.ticketNumber
          );
          if (queuePos === -1) {
            props.navigation.push('Home', { resetForm: true });
            return;
          }
          console.log('queuePos', queuePos);
          const peopleAheadMsg = getNumPeopleAheadMsg(
            queuePos,
            params.queueSettings.numAheadToBeNotified,
            sequenceInfo,
            currentGuestState.current.ticketNumber
          );
          // console.log('queuePos', queuePos);
          // console.log('getNumPeopleAheadMsg(queuePos)', peopleAheadMsg);
          if (queuePos > -1) setPeopleAheadMsg(peopleAheadMsg);
          else console.log('queuePos > -1', queuePos > -1);

          const waitTimeObj = JSON.parse(sequenceInfo.waitTime);
          const estWaitTime_ = waitTimeObj.estMedWaitTime * queuePos;
          console.log('waitTimeObj', waitTimeObj);
          console.log(
            'JSON.parse(sequenceInfo.waitTime',
            JSON.parse(sequenceInfo.waitTime)
          );
          setEstWaitTime(estWaitTime_);

          if (
            currentGuestState.current.assignedLocation &&
            currentGuestState.current.assignedLocation.length > 0
          )
            return;

          setIsBliking(true);

          const regFormData = {
            ...props?.route?.params,
            estWaitTime: estWaitTime,
            peopleAheadMsg: peopleAheadMsg,
          };
          dispatch(guestActions.createGuest(regFormData));
        },
        0
      );
    };

    subscribeToAWSGraphQL().then(() => {
      console.log(
        'subsOnUpdateWaitListByGuestId2',
        subsOnUpdateWaitListByGuestId
      );
    });

    return () => {
      unsubscribeGraphQL();
      console.log('unsubscribeGraphQL--cleanUp-');
    };
  }, []);

  // useEffect(() => {
  //   const blinkIntreval = setInterval(function () {
  //     if (queueInfoColor === 'green') setQueueInfoColor('grey');
  //     else setQueueInfoColor('green');
  //   }, 500);
  //   return () => {
  //     // cleanup
  //   };
  // }, []);
  return (
    <View style={[styles.container, { padding: 0, margin: 0 }]}>
      {/* <ConfirmDialog
      // isShowing={isDialogShowing}
      // hideDialogHandle={() => hideDialogHandle()}
      // onDeleteConfirmed={() => onCancelConfirmed()}
      // title={
      //   'Deactivate' //+ getAssetNameById(selectedItemToDelete, assets) + "?"
      // }
      // Subheading={
        'This device will be deactivated.'}
      /> */}
      <DialogBox
        title='Confirmation'
        message='Are you sure you want to cancel your check-in?'
        onPressYes={onCancelConfirmed}
        onPressNo={hideDialogHandle}
        isShowing={isDialogShowing}
        isConfirmation={true}
        // hideDialogHandle={() => hideDialogHandle()}
        // onDeleteConfirmed={() => onCancelConfirmed()}
        // title={
        //   'Deactivate' //+ getAssetNameById(selectedItemToDelete, assets) + "?"
        // }
        // Subheading={'This device will be deactivated.'}
      />
      <View
        style={{
          alignItems: 'center',
          // marginTop: 50,
          marginHorizontal: 20,
        }}
      >
        {console.log('peopleAheadMsg:', peopleAheadMsg)}
        <Text style={styles.medText}>Registration Complete</Text>
        <Text style={styles.smallText}>
          <Text style={styles.smallText}>
            {
              'Your name has been added to our wait list. This page will show your queue status in real-time. No need to refresh this page.'
            }
          </Text>
          {/* <Text>A text alert will inform you 15 minutes before your turn.</Text> */}
          {withNotif && (
            <Text>
              <Text>
                {/* {'\n'} */}
                {
                  // 'Shortly before your turn to be served, a text alert will be sent to '
                  "When it's your time to proceed inside, a text alert will be sent to "
                }
              </Text>
              <Text style={styles.smallBoldText}>{guestPhoneNum + '.'}</Text>
            </Text>
          )}
          {!withNotif && (
            <Text>
              {/* <Text>{'Please proceed inside when there are about '}</Text>
              <Text style={styles.smallBoldText}>
                {minBeforeTurn + ' minutes'}
              </Text>
              <Text>{' left in your estimated wait time.'}</Text> */}
              {/* <Text>
                {'Below is the realtime update on your queue status.'}
              </Text> */}
            </Text>
          )}
        </Text>
      </View>
      <BlinkingView
        duration={180}
        repeatCount={2}
        isEnabled={isBliking}
        onEnd={() => {
          console.log('onEnd');
          setIsBliking(false);
        }}
      >
        <View style={[styles.infoContainer]}>
          <Text style={styles.infoText}>YOUR TICKET NUMBER IS</Text>
          <Text style={[styles.infoTextLarge, {}]}>
            {currentGuest.ticketNumber}
          </Text>
          {console.log(
            'currentGuest.assignedLocation?.length',
            currentGuest.assignedLocation?.length
          )}
          {currentGuest.assignedLocation &&
          currentGuest.assignedLocation.length > 0 ? (
            <View style={{ flexDirection: 'column' }}>
              <Text style={styles.infoText3}>
                {"It's now your turn!\nPlease proceed now to "}
              </Text>
              <Text style={[styles.smallBoldText, styles.infoText3]}>
                {currentGuest.assignedLocation}
                {'.'}
              </Text>
            </View>
          ) : (
            <View>
              <Text style={styles.infoText3}>
                <Text>{peopleAheadMsg}</Text>
              </Text>
              <Text style={styles.infoText}>
                <Text>Estimated wait time: </Text>
                <Text>
                  {estWaitTime < 1
                    ? ' < 1 min.'
                    : convertMinToHrMin(estWaitTime)}
                </Text>
              </Text>
              <ActivityIndicator
                style={{ marginTop: 10, marginBottom: -5 }}
                size={20}
                color='#ffffff'
              ></ActivityIndicator>
            </View>
          )}
        </View>
      </BlinkingView>

      <TouchableOpacity
        style={{ alignItems: 'center', paddingBottom: 20 }}
        onPress={() => {
          setIsDialogShowing(true);
        }}
      >
        <Text style={styles.medUndelinedText}>Cancel my registration</Text>
      </TouchableOpacity>
      <View
        style={{
          alignItems: 'center',
          marginVertical: 20,
          marginHorizontal: 10,
          // justifyContents: 'flex-start',
          // alignItems: 'flex-start',
        }}
      >
        <Text style={styles.smallBoldText}>
          {"What to do when it's about your turn?"}
        </Text>
        <Text style={styles.smallText}>
          {/* <Text>{'Please proceed inside when there are about '}</Text>
          <Text style={styles.smallBoldText}>15 minutes</Text>
          <Text>{' left in your estimated wait time.'}</Text> */}
          <Text style={[styles.smallText, { textAlign: 'left' }]}>
            {params.queueSettings.instructionalMessage
              ? params.queueSettings.instructionalMessage
              : 'Follow instructions posted on the front door.'}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default ConfirmationScreen;
