import { StyleSheet, Platform } from 'react-native';
import Colors from '../constants/colors';
import Constants from 'expo-constants';
import { mScale } from '../utils/myUtils';
import colors from '../constants/colors';

// const globalStyles = StyleSheet.create({
let defTitleFontSize = mScale(22);
let defFontSize = mScale(18);
let defSmallFontSize = mScale(16);
const globalStyles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 99999,
    // margin: 60,
  },
  activityLabel: {
    fontSize: mScale(32),
    fontFamily: 'poppins-regular',
    color: 'white',
    textAlign: 'center',
    marginBottom: mScale(50),
  },
  container: {
    flex: 1,
    height: '100%',
    backgroundColor: '#F5F5F5',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: mScale(18),
  },
  infoText: {
    fontSize: defFontSize,
    fontFamily: 'poppins-regular',
    // marginBottom: -2,
    // marginRight: 10,
    color: 'white',
    textAlign: 'center',
  },
  infoText3: {
    fontSize: defFontSize,
    fontFamily: 'poppins-regular',
    // marginBottom: -2,
    // marginRight: 10,
    color: 'white',
    textAlign: 'center',
  },
  infoText2: {
    fontSize: defFontSize,
    fontFamily: 'poppins-light',
    // marginBottom: -2,
    // marginRight: 10,
    color: 'black',
    textAlign: 'center',
  },
  infoTextLarge: {
    fontSize: mScale(56),
    fontFamily: 'poppins-regular',
    // marginBottom: -2,
    // marginRight: 10,
    color: 'white',
    textAlign: 'center',
  },
  medText: {
    fontSize: defFontSize,
    fontFamily: 'poppins-regular',
    // marginBottom: -2,
    // marginRight: 10,
    color: 'black',
    textAlign: 'center',
  },
  medUndelinedText: {
    fontSize: defFontSize,
    fontFamily: 'poppins-regular',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    // marginBottom: -2,
    // marginRight: 10,
    color: 'green',
    textAlign: 'center',
  },
  smallText: {
    fontSize: defSmallFontSize,
    fontFamily: 'poppins-regular',
    // marginBottom: -2,
    // marginHorizontal: 10,
    color: 'black',
    color: 'dimgray',
    textAlign: 'center',
  },
  smallBoldText: {
    fontSize: defSmallFontSize,
    fontFamily: 'poppins-semibold',
    fontWeight: '800',
    // marginBottom: -2,
    // marginHorizontal: 10,
    color: 'black',
    color: 'dimgray',
    textAlign: 'center',
  },

  button: {
    alignSelf: 'center',
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    height: mScale(60),
    backgroundColor: 'green',
    borderRadius: mScale(30),
    // marginTop: 20,
    marginVertical: mScale(25),
  },
  infoContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    // height: mScale(60),
    backgroundColor: 'green',
    // borderRadius: mScale(80),
    paddingVertical: 20,
    paddingHorizontal: 10,
    marginHorizontal: 0,
    marginVertical: mScale(30),
  },
  buttonLabel: {
    fontSize: mScale(22),
    // fontWeight: 'bold',
    alignSelf: 'center',
    color: 'white',
    fontFamily: 'poppins-regular',
  },
  title: {
    fontSize: defTitleFontSize,
    // fontWeight: 'bold',
    color: 'black',
    fontFamily: 'poppins-regular',
    textAlign: 'center',
    // marginBottom: mScale(-50),
  },
  icon: {
    width: mScale(100),
    height: mScale(100),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: mScale(10),
  },
  inputFieldBox: {
    paddingBottom: 5,
    // backgroundColor: 'green',
  },
  flatListItemSemiRound: {
    flex: 1,
    backgroundColor: 'white',
    marginHorizontal: 20,
    marginVertical: 10,
    padding: 20,
    borderRadius: 10,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  flatListItemRound: {
    backgroundColor: 'white',
    marginHorizontal: mScale(10),
    marginVertical: mScale(10),
    padding: mScale(20),

    height: mScale(70),
    borderRadius: mScale(35),
    flexDirection: 'row',
    borderWidth: mScale(2),
    borderColor: colors.textInputBorderColor,
  },
});

export { globalStyles, defFontSize, defTitleFontSize };
