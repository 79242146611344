import Guest from '../../models/guest';

// import { API, graphqlOperation, AUTH_MODE } from 'aws-amplify';
// import Storage from '@aws-amplify/storage';
//TODO: remove react-native-get-random-values when Expo SDK39 is released
// import "react-native-get-random-values";
// import { v4 as uuid } from 'uuid';
// import { Asset as ExpoAsset } from 'expo-asset';
// ExpoAsset.fromModule(require("../../assets/splash.png")).uri

// import {
//   removeEmptyOrNull,
// } from '../../components/utils/myUtils';

// import * as myUtils from '../../components/utils/myUtils';

// const clone = require('rfdc')();

// import {
//   qryWaitListGuestById,
//   listCheckedInGuests,
//   listWaitLists,
//   checkInGuest as qryCheckInGuest,
// } from '../../src/graphql/queries';
// import { updateWaitList } from '../../src/graphql/mutations';
// import * as customQueries from '../../src/graphql/myCustomQueries';

export const DELETE_GUEST = 'DELETE_GUEST';
export const CREATE_GUEST = 'CREATE_GUEST';
export const UPDATE_GUEST = 'UPDATE_GUEST';
export const SET_GUEST = 'SET_GUEST';
export const CLEAR_GUEST = 'CLEAR_GUEST';
// export const PROC_PENDING_UPLOAD = "PROC_PENDING_UPLOAD";
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';

const deleteIrrelevantProps = (myObj) => {
  delete myObj.withPendingUpload;
  delete myObj.pendingAction;
  delete myObj.owner;
  delete myObj.__typename;
};

// export const fetchPrevWaitingGuest = (smsKeyword, assignedUserId) => {
//   return async (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         console.log('xxxxxxxx', smsKeyword.trim(), assignedUserId);

//         const WaitingGuestFromGraphQl = await API.graphql(
//           graphqlOperation(listWaitLists, {
//             filter: {
//               smsKeyword: { eq: smsKeyword },
//               assignedUserId: { eq: assignedUserId },
//             },
//             limit: 999,
//             authMode: 'AMAZON_COGNITO_USER_POOLS',
//           })
//         );
//         console.log('guestsFromGraphQl', prevWaitingGuestFromGraphQl);

//         if (
//           prevWaitingGuestFromGraphQl.data.listWaitLists &&
//           prevWaitingGuestFromGraphQl.data.listWaitLists.items.length > 0
//         ) {
//           let prevGuest =
//             prevWaitingGuestFromGraphQl.data.listWaitLists.items[0];

//           console.log('prevGuest', prevGuest);

//           resolve(prevGuest);
//         } else {
//           resolve();
//         }
//       } catch (err) {
//         console.log(err);
//         console.log('error fetching guests');
//         reject(err);
//       }
//     });
//   };
// };

// //++++++++++++
// export const fetchServedGuests = (companyId, userId, dateStamp) => {
//   return async (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         console.log('fetchServedGuests', companyId, userId, dateStamp);
//         const ListCheckedInGuests = await API.graphql(
//           graphqlOperation(customQueries.qryListCheckedInGuests, {
//             filter: {
//               companyId: { eq: companyId },
//               assignedUserId: { eq: userId },
//               dateStamp: { eq: dateStamp },
//             },
//             authMode: 'AMAZON_COGNITO_USER_POOLS',
//             limit: 999,
//           })
//         );
//         console.log('guestsFromGraphQl', ListCheckedInGuests);
//         let downloadedGuests =
//           ListCheckedInGuests.data.listCheckedInGuests.items;
//         downloadedGuests = downloadedGuests.sort(
//           mySortByValues('serviceStarted', 'desc')
//         );

//         if (downloadedGuests) {
//           const guestsCopy = clone(downloadedGuests);
//           console.log('guestsCopy1', guestsCopy);
//           dispatch({
//             type: SET_GUESTS,
//             guests: guestsCopy,
//           });
//           resolve(guestsCopy);
//         } else {
//           resolve();
//         }
//       } catch (err) {
//         console.log(err);
//         console.log('error fetching guests');
//         reject(err);
//       }
//     });
//   };
// };

// export const fetchGuest = (guestId) => {
//   return async (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const guestsFromGraphQl = await API.graphql(
//           graphqlOperation(qryWaitListGuestById, {
//             id: guestId,
//             authMode: 'AMAZON_COGNITO_USER_POOLS',
//             limit: 1,
//           })
//         );
//         console.log('guestsFromGraphQl', guestsFromGraphQl);
//         let downloadedGuest = guestsFromGraphQl.data.qryWaitListGuestById;
//         // downloadedGuests = downloadedGuests.sort(
//         //   mySortByValues('createdAt', 'asc')
//         // );

//         if (downloadedGuest && downloadedGuest.items.length > 0) {
//           // dispatch({
//           //   type: CREATE_GUEST,
//           //   guestData: downloadedGuest,
//           // });
//           const guest = downloadedGuest.items[0];
//           console.log(
//             'parseInt(guest.ticketNumber)1',
//             isNaN(guest.ticketNumber)
//           );
//           // if (isNaN(guest.ticketNumber)) {
//           //   console.log('XXXXXX');
//           //   // guest.ticketNumber =
//           //   //   guest.ticketNumber.slice(0, 3) +
//           //   //   '-' +
//           //   //   guest.ticketNumber.slice(3);
//           // }
//           console.log('downloadedGuest', guest);
//           resolve(guest);
//         } else {
//           resolve(null);
//         }
//       } catch (err) {
//         console.log(err);
//         console.log('error fetching guests');
//         reject(err);
//       }
//     });
//   };
// };

// export const clearGuest = () => {
//   return async (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         dispatch({ type: CLEAR_GUEST });
//         resolve();
//       } catch (err) {
//         console.log(err);
//         reject(err);
//       }
//     });
//   };
// };
export const checkGuestInfoLocally = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(
          'checkGuestInfoLocally',
          getState().guestReducer.currentGuest
        );
        resolve(getState().guestReducer.currentGuest);
      } catch (err) {
        console.log(err);
        console.log('error in deleting cloud guest');

        // throw err;
        reject(err);
      }
    });
  };
};

export const deleteGuest = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: DELETE_GUEST });
        console.log('SUCCESS!!!');
        resolve();
      } catch (err) {
        console.log(err);
        console.log('error in deleting cloud guest');

        // throw err;
        reject(err);
      }
    });
  };
};

export const createGuest = (guest) => {
  return async (dispatch, getState) => {
    // const guestCopy = clone(guest);
    console.log('guestCopy1', guest);

    dispatch({
      type: CREATE_GUEST,
      guest: guest,
    });
  };
};

// export const createGuest = (guest) => {
//   return async (dispatch, getState) => {
//     const guestCopy = clone(guest);
//     console.log('guestCopy1', guestCopy);

//     dispatch({
//       type: CREATE_GUEST,
//       guest: guestCopy,
//     });
//   };
// };

//++++++++++++
export const checkInGuest = (guest) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      console.log('guest-=-=-', guest);
      deleteIrrelevantProps(guest);
      // myUtils.removeEmptyOrNull(guest);
      const checkInGuestGraphQl = API.graphql(
        graphqlOperation(qryCheckInGuest, {
          guestInfo: JSON.stringify(guest),
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })
      );
      console.log('checkInGuestGraphQl', checkInGuestGraphQl);
      console.log('checkInGuest', guest);
      // const updatedGuest = checkInGuestGraphQl.data.checkInGuest;
      dispatch({
        type: 'CREATE_GUEST',
        guest: guest,
      });
      resolve(guest);
    });
  };
};

//++++++++
// export const updateGuest = (guest) => {
//   return async (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//       deleteIrrelevantProps(guest);
//       console.log('guest', guest);
//       const updatedGuestFromGraphQl = await API.graphql(
//         graphqlOperation(updateWaitList, {
//           input: guest,
//           authMode: 'AMAZON_COGNITO_USER_POOLS',
//           limit: 1,
//         })
//       );
//       console.log(
//         'updatedGuestFromGraphQl',
//         updatedGuestFromGraphQl.data.updateWaitList
//       );
//       const updatedGuest = updatedGuestFromGraphQl.data.updateWaitList;
//       // dispatch({
//       //   type: 'UPDATE_GUEST',
//       //   id: updatedGuest.id,
//       //   guestData: updatedGuest,
//       // });
//       resolve(updatedGuest);
//     });
//   };
// };

export const updateGuest = (guest) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({
        type: 'UPDATE_GUEST',
        guest: guest,
      });
      resolve();
    });
  };
};
