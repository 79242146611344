import React, { useState, useEffect, useCallback } from 'react';
import { Text, TouchableOpacity, View, StyleSheet, Alert } from 'react-native';
import Modal from 'react-native-modal';
import { mScale, toISOLocal } from '../../utils/myUtils';
import { globalStyles } from '../../styles/global';

let autoDismissTimeout;
let intervalTimer;
export function DialogBox(props) {
  const [countdown, setCountdown] = useState(props.dismissInSec);
  if (autoDismissTimeout) {
  }
  if (!props.isConfirmation && props.isShowing) {
    useEffect(() => {
      const timer = () => {
        autoDismissTimeout = setTimeout(function () {
          console.log('autoDismissTimeout');
          clearTimeout(autoDismissTimeout);
          clearInterval(intervalTimer);
          props.onPressNo();
        }, props.dismissInSec * 1000);

        intervalTimer = setInterval(() => {
          console.log('intervalTimer');
          setCountdown((sec) => sec - 1);
        }, 1000);
      };

      timer();

      return () => {
        clearTimeout(autoDismissTimeout);
        clearInterval(intervalTimer);
        setCountdown(props.dismissInSec);
        console.log('clearTimeout');
      };
    }, [props]);
  }

  if (props.isShowing) {
    return (
      <View
        style={{
          // position: 'absolute',
          // top: 0,
          // left: 0,
          // margin: 0,
          // padding: 0,
          // right: 0,
          // bottom: 0,
          // flex: 1,
          justifyContent: 'center',
          alignItems: 'center',

          // backgroundColor: 'yellow',
          // zIndex: 9999,
        }}
      >
        <Modal isVisible={true}>
          <View
            style={{
              // flex: 1,
              // backgroundColor: 'rgba(240,240,240,1)',
              backgroundColor: 'white',
              alignSelf: 'center',
              minWidth: mScale(320),
              minHeight: mScale(250),
              height: 'auto',
              // paddingVertical: 40,
              borderRadius: 10,
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{props.title}</Text>
            </View>
            <Text style={styles.message}>{props.message}</Text>
            {!props.isConfirmation && (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={[globalStyles.button, styles.button]}
                  onPress={() => {
                    clearTimeout(autoDismissTimeout);
                    clearInterval(intervalTimer);
                    setCountdown(props.dismissInSec);
                    props.onPressNo();
                  }}
                >
                  <Text style={styles.buttonLabel}>
                    {'Close (' + countdown + ')'}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            {props.isConfirmation && (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={[globalStyles.button, styles.button]}
                  onPress={props.onPressYes}
                >
                  <Text style={styles.buttonLabel}>Yes</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[globalStyles.button, styles.button]}
                  onPress={props.onPressNo}
                >
                  <Text style={styles.buttonLabel}>No</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </Modal>
      </View>
    );
  } else {
    return <></>;
  }
}

const styles = StyleSheet.create({
  titleContainer: {
    position: 'absolute',
    padding: 20,
    // flex: 1,
    // alignSelf: 'flex-start',
    height: mScale(60),
    width: '100%',
    // backgroundColor: 'white',
    backgroundColor: 'rgba(240,240,240,1)',
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'poppins-semibold',
    fontSize: mScale(18),
    fontWeight: '800',
    textAlign: 'center',
  },
  message: {
    fontFamily: 'poppins-light',
    fontSize: mScale(18),
    textAlign: 'left',
    marginHorizontal: 30,
  },
  buttonContainer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    height: mScale(80),
    // backgroundColor: 'red',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  button: {
    alignSelf: 'center',
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%',
    height: mScale(50),
    backgroundColor: 'white',
    borderRadius: mScale(30),
    // marginTop: 20,
    marginVertical: mScale(30),
    marginHorizontal: 10,
  },
  buttonLabel: {
    fontSize: mScale(22),
    fontWeight: 'bold',
    color: 'green',
    fontFamily: 'poppins-regular',
  },
});
