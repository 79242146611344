import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  SHOW_CONFIRM_DLG,
  HIDE_CONFIRM_DLG,
  SAVE_APP_SETTINGS,
} from '../actions/app';

const initialAppState = {
  isConfirmDlgShowing: false,
  appSettings: {},
};

export default (state = initialAppState, action) => {
  switch (action.type) {
    case SHOW_CONFIRM_DLG:
      console.log(SHOW_CONFIRM_DLG);
      return {
        isConfirmDlgShowing: true,
      };
    case HIDE_CONFIRM_DLG:
      return {
        isConfirmDlgShowing: false,
      };
    case SAVE_APP_SETTINGS:
      // let evalData = JSON.stringify(action?.appSettings);

      AsyncStorage.setItem('appSettings', action?.appSettings);

      const appSettingsObj = JSON.parse(action?.appSettings);
      console.log('SAVE_APP_SETTINGS_1', appSettingsObj);
      console.log(state);
      return {
        ...state,
        appSettings: appSettingsObj,
      };
  }
  return state;
};
