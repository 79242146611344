/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:e6849dae-b3e1-4412-ae71-8ce7d7fa261c',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_YqzPSRxd5',
  aws_user_pools_web_client_id: '73lc4jvujvflv8u841lt94kmk0',
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: 'AdminQueries',
      endpoint: 'https://fvenljzks0.execute-api.us-east-1.amazonaws.com/test',
      region: 'us-east-1',
    },
  ],
  aws_appsync_graphqlEndpoint:
    'https://ycilhivwrzb25ddi3hiu4u6jwq.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AWS_IAM',
  aws_appsync_apiKey: 'da2-kfciwbb7qzdf7iabuoalax3t3m',
  aws_user_files_s3_bucket: 'sanisign-admin-bucket104426-test',
  aws_user_files_s3_bucket_region: 'us-east-1',
};

export default awsmobile;
