export default {
  defBackgroundColor: '#424242',
  dark_background: '#011627',
  primary: '#6200ee', // "#3d7886"
  beauBlue: '#CEE5F2',
  beauBlue2: '#ACCBE1',
  airSupeiorityBlue: '#7C98B3',
  darkElectricBlue: '#637081',
  //======
  primary: '#6200ee', //"#3d7886",
  lavenderWeb: '#D9DBF1',

  lavenderGray: '#D0CDD7',
  cadetBlueCrayola: '#ACB0BD',
  midnightGreen: '#0B3948',
  //======
  deepSquidInk: '#152939',
  linkUnderlayColor: '#FFF',
  errorIconColor: '#DD3F5B',
  rose: '#FF007F',
  textInputColor: '#000000',
  textInputBorderColor: '#C4C4C4',
  placeholderColor: '#C7C7CD',
  buttonColor: '#6200ee', //"#3d7886",
  disabledButtonColor: '#d1dfe3',
  //=============
  hanPurple: '#6200EE',
  lavenderWeb: '#D8DCFF',
  bluePurple: '#AEADF0',
  puce: '#C38D94',
  roseDust: '#A76571',
  richBlack: '#0E1116',
  battenPink: '#9E7B9B',
  brightLilac: '#CB9CF2',
  mistyRose: '#F9E0D9',
};
