//@ts-check
import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { globalStyles as styles } from '../styles/global';

import { convertMinToHrMin } from '../utils/myUtils';
import * as myModule from '../utils/myModule';

const CancelledScreen = (props) => {
  const propsObj = props.route.params;
  console.log('CancelledScreen props', props);
  return (
    <View
      style={[
        styles.container,
        { padding: 0, margin: 0, justifyContent: 'center' },
      ]}
    >
      <View
        style={[
          // styles.container,
          {
            padding: 0,
            margin: 0,
            alignSelf: 'center',
          },
        ]}
      >
        {/* <ConfirmDialog
      // isShowing={isDialogShowing}
      // hideDialogHandle={() => hideDialogHandle()}
      // onDeleteConfirmed={() => onCancelConfirmed()}
      // title={
      //   'Deactivate' //+ getAssetNameById(selectedItemToDelete, assets) + "?"
      // }
      // Subheading={'This device will be deactivated.'}
      /> */}
        <View
          style={{
            alignItems: 'center',
            margin: 50,
            marginHorizontal: 20,
          }}
        >
          <Text style={styles.medText}>Check-in Cancelled</Text>
          <Text style={styles.smallText}>
            <Text style={styles.smallText}>
              {'Your name has been remove from the wait list. '}
            </Text>
          </Text>
        </View>

        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            myModule.resetNavigation(props, propsObj.isMultiService);
            // // console.log('props.navigation.reset propsObj', propsObj);
            // if (propsObj.isMultiService) {
            //   // props.navigation.push('Services', { resetForm: true });
            //   props.navigation.reset({
            //     index: 0,
            //     routes: [{ name: 'Services', params: { resetForm: true } }],
            //   });
            // } else {
            //   // props.navigation.push('Home', { resetForm: true });
            //   props.navigation.reset({
            //     index: 0,
            //     routes: [{ name: 'Home', params: { resetForm: true } }],
            //   });
            // }
          }}
        >
          <Text style={styles.buttonLabel}>Done</Text>
        </TouchableOpacity>
        {/* <View
          style={{
            alignItems: 'center',
            margin: 20,
            // justifyContents: 'flex-start',
            // alignItems: 'flex-start',
          }}
        >
          <Text style={styles.smallBoldText}>
            {"What to do when it's about your turn?"}
          </Text>
          <Text style={styles.smallText}>
            <Text style={[styles.smallText, { textAlign: 'left' }]}>
              {
                'Wear mask and maintain social distancing. Follow instructions posted on the front door.'
              }
            </Text>
          </Text>
        </View> */}
      </View>
    </View>
  );
};

export default CancelledScreen;
