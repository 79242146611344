import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleSheet,
  View,
  Text,
  Image,
  FlatList,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from 'react-native';
import { mScale, toISOLocal } from '../utils/myUtils';
import InputBox from '../components/UI/forms/InputBox';
import { useForm } from 'react-hook-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalStyles as styles } from '../styles/global';
import * as guestActions from '../redux_store/actions/guest';
import { DialogBox } from '../components/UI/DialogBox';

import * as myModule from '../utils/myModule';

const defaultName = 'PCID';

// function getParameterByName(paramKey, url = window.location.href) {
//   console.log('getParameterByName', url);
//   name = name.replace(/[\[\]]/g, '\\$&');
//   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//     results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return '';
//   return decodeURIComponent(results[2].replace(/\+/g, ' '));
//   // var searchParams1 = new URLSearchParams(url);
// }

const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

// const qs = (a) => {
//   if (a == '') return {};
//   var b = {};
//   for (var i = 0; i < a.length; ++i) {
//     var p = a[i].split('=', 2);
//     if (p.length == 1) b[p[0]] = '';
//     else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
//   }
//   return b;
// }(window.location.search.substr(1).split('&'));

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

const NotifyMeScreen = (props) => {
  console.log('NotifyMeScreen props', props);
  // console.log('NotifyMeScreen formData', props.route.params?.formData);

  const dispatch = useDispatch();
  const [prevFormData, setFormData] = useState(props.route.params?.formData);
  // const [withNotif, setWithNotif] = useState(false);
  const [estWaitTime, setEstWaitTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogShowing, setIsDialogShowing] = useState(false);

  const fieldList = [
    {
      name: 'phoneNum',
      // autoFocus: true,
      label: 'Phone',
      defaultValue: prevFormData?.phoneNum,
      keyboardType: 'phone-pad',
      maxLength: 128,
      rules: {
        required: 'This is required.',
        //   pattern: props.pattern ? props.pattern : null,
        maxLength: 128
          ? {
              value: 128,
              message: 'This input exceeds maximum character limit.',
            }
          : null,
        // validate: (value) =>
        //   value === formData?.phoneNum || 'The phone numbers do not match.',
      },
    },
  ];

  let fieldList_ref = [];
  fieldList.map((item, index) => {
    const item_ref = useRef();
    fieldList_ref.push(item_ref);
  });

  const form = useForm({
    mode: 'onChange',
    // defaultValues: {
    //   userId: '',
    //   firstName: '',
    //   lastName: '',
    //   location: '',
    // },
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    reset,
  } = form;

  const handleOnPress = async () => {
    handleSubmit(onSubmit);
    console.log('handleOnPress');
  };

  const onSubmitWithNotif = async (data) => {
    console.log('onSubmitWithNotif', data);
    onSubmit(data, true);
  };

  const onSubmitWithoutNotif = async (data) => {
    onSubmit(data, false);
  };

  const onSubmit = async (data, withNotifConsent) => {
    // console.log('Submit event', formData, e);
    // console.log('Submit event', e);

    const updatedFormData = { ...prevFormData, ...data };

    setIsLoading(true);
    var formattedPhoneNum = updatedFormData.phoneNum;
    var numOnly = updatedFormData.phoneNum.replace(/[^0-9]/g, '');
    updatedFormData.phoneNum = numOnly;
    console.log('onSubmit', updatedFormData);
    // let msgKey = '';
    // let companyId = '';
    // if (Platform.OS === 'web') {
    //   msgKey = getParameterByName('msgkey');
    //   companyId = getParameterByName('companyId');
    // }

    const channelType = 'WebForm';
    const guestInfo = {
      ...updatedFormData,
      method: 'addToWaitList',
      // service: props.route.params.service,
      serviceId: props.route.params.selectedService?.code
        ? props.route.params.selectedService?.code
        : '1',
      companyId: props.route.params.companyId,
      companyName: props.route.params.companyName,
      companyCode: props.route.params.companyCode,
      regType: 'phoneNum',
      channelType: channelType,
      regValue: updatedFormData.phoneNum,
      withNotifConsent: withNotifConsent,
    };

    // const url =
    //   'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/v1/newguest';
    const url =
      'https://xo615nbxgj.execute-api.us-east-1.amazonaws.com/' +
      props.route.params.version +
      '/newguest';
    console.log('url', guestInfo, url, props.route.params);
    // const data = guestInfo;
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(guestInfo),
      method: 'POST',
      // mode: 'no-cors',
    };
    console.log('params', params);

    try {
      const apiResponse = await fetch(url, params);

      if (apiResponse && apiResponse.ok) {
        // console.log('response ----> ', apiResponse);
        apiResponse.json().then((json) => {
          console.log('response ----> ', json);

          if (json.withError) {
            setIsDialogShowing(true);
            return;
          }
          if (!json.isCheckInAvailable) {
            const newProps = {
              ...props.route.params,
              ...json,
            };
            props.navigation.setParams(newProps);
            console.log('newProps', newProps, props);
            myModule.resetNavigation(
              props,
              params.isMultiService,
              true,
              json.isCheckInAvailable
            );
            return;
          }

          const dateStamp = toISOLocal(new Date()).slice(0, 10);
          const regFormData = {
            ...props.route.params,
            withNotifConsent: withNotifConsent,
            channelType: channelType,
            minBeforeTurn: json.minBeforeTurn,
            ticketNumber: json.ticketNumber,
            estWaitTime: json.estMedWaitTime,
            peopleAheadMsg: json.peopleAheadMsg,
            formattedPhoneNum: formattedPhoneNum,
            formData: updatedFormData,
            guestWaitListId: json.guestWaitListId,
            isCheckInAvailable: json.isCheckInAvailable,
            dateStamp: dateStamp,
          };

          dispatch(guestActions.createGuest(regFormData));

          props.navigation.push('Confirmation', {
            ...regFormData,
          });
        });
      } else {
        console.log('response NOT OK', apiResponse);
      }
    } catch (error) {}
    setIsLoading(false);

    // console.log('response1', JSON.parse(JSON.stringify(response)));
    // console.log('response2', JSON.stringify(response));

    // var lambda = new AWS.Lambda();

    // var name = xxxx;
    // var input;
    // if (name.value == null || name.value == '') {
    //   input = {};
    // } else {
    //   input = {
    //     name: name.value,
    //   };
    // }
    // lambda.invoke(
    //   {
    //     FunctionName: 'SaniSignSmsHandlerFunc-prod',
    //     Payload: JSON.stringify(input),
    //   },
    //   function (err, data) {}
    // );
  };

  const handleChange = (value) => {
    console.log('value', value);
    let normInput = normalizeInput(value, value.substring(1, 1));
    console.log('normalizeInput', normInput);
    return normInput;
  };

  // handleSubmit(e) {
  //   e.preventDefault();
  //   const error = validateInput(this.state.phone);

  //   this.setState({ error }, () => {
  //      if(!error) {
  //        setTimeout(() => {
  //          alert(JSON.stringify(this.state, null, 4));
  //        }, 300)
  //      }
  //   });
  // }

  // handleReset() {
  //    this.setState({ phone: "", error: "" });
  // };
  // if (isLoading)
  //   return (

  //   );
  const hideDialogHandle = async () => {
    setIsDialogShowing(false);
  };

  return (
    <View style={styles.container}>
      {isLoading && (
        <View style={styles.activityIndicator}>
          <Text style={styles.activityLabel}>Please wait...</Text>
          <ActivityIndicator
            // style={{ height: 380 }}
            size={100}
            color='#ffffff'
          ></ActivityIndicator>
        </View>
      )}

      <DialogBox
        title='Server Busy'
        message='The server is busy right now, please try again later.'
        onPressNo={hideDialogHandle}
        isShowing={isDialogShowing}
        isConfirmation={false}
        autoDismiss={true}
        dismissInSec={5}
        // hideDialogHandle={() => hideDialogHandle()}
        // onDeleteConfirmed={() => onCancelConfirmed()}
        // title={
        //   'Deactivate' //+ getAssetNameById(selectedItemToDelete, assets) + "?"
        // }
        // Subheading={'This device will be deactivated.'}
      />
      <View style={{ alignItems: 'center', margin: 10 }}>
        <Text style={styles.medText}>SMS Notification</Text>
        <Text style={styles.smallText}>
          <Text style={styles.smallText}>
            {
              "To receive a text notification on when it's about your turn, confirm your mobile phone number below then tap the "
            }
          </Text>
          <Text style={styles.smallBoldText}>Notify Me!</Text>
          <Text style={styles.smallText}>{' button.'}</Text>
        </Text>
      </View>
      <View
        style={{
          //   flex: 1,
          width: '100%',
          //   height: '100%',
          // backgroundColor: 'red',
          justifyContent: 'flex-start',
          marginVertical: 15,
          //   alignItems: 'center',
          //   backgroundColor: appConst.DEF_BACKGROUND_COLOR,
        }}
      >
        <KeyboardAwareScrollView style={{ marginTop: mScale(20) }}>
          {fieldList.map((item, index) => {
            // console.log('index', index);
            return (
              <InputBox
                key={index}
                fieldIndex={index}
                // autoFocus={item.autoFocus}
                name={item.name}
                field={item}
                rules={item.rules}
                label={item.label}
                form={form}
                ref={fieldList[index]}
                defValue={
                  fieldList[index].defaultValue
                    ? fieldList[index].defaultValue
                    : ''
                }
                // ref={register({
                //   validate: (value) =>
                //     value === password.current || 'The passwords do not match',
                // })}
                nextInput={fieldList[index + 1]}
                returnKeyType='next'
                fieldCount={fieldList.length}
                handleChange={
                  fieldList[index].keyboardType === 'phone-pad'
                    ? handleChange
                    : null
                }
                onSubmit={handleSubmit(onSubmitWithNotif)}
                // onSubmitEditing={() => {
                //   ref_lastName.current.focus();
                // }}
              />
            );
          })}
        </KeyboardAwareScrollView>
        <TouchableOpacity
          style={styles.button}
          onPress={handleSubmit(onSubmitWithNotif)}
        >
          <Text style={styles.buttonLabel}>Notify Me!</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ alignItems: 'center', margin: 0 }}
          onPress={handleSubmit(onSubmitWithoutNotif)}
        >
          <Text style={styles.medUndelinedText}>No thanks!</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ alignItems: 'center', marginTop: 60 }}
          onPress={() => {
            props.navigation.goBack();
          }}
        >
          <Text style={styles.medUndelinedText}>Go to previous screen</Text>
        </TouchableOpacity>
        {/* <FlatList
          data={fieldList}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => {
            return (
              <InputBox
                name={item.name}
                field={item}
                required='.'
                label={item.label}
                form={form}
                ref={ref_firstName}
                nextInput={ref_lastName}
                returnKeyType='next'
                // onSubmitEditing={() => {
                //   ref_lastName.current.focus();
                // }}
              />
            );
          }}
        /> */}
      </View>
    </View>
  );
};

export default NotifyMeScreen;

export const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

export const validateInput = (value) => {
  let error = '';

  if (!value) error = 'Required!';
  else if (value.length !== 14)
    error = 'Invalid phone format. ex: (555) 555-5555';

  return error;
};
