//https://dev.to/bhatvikrant/redux-persist-v6-in-detail-react-10nh

import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'; // imports from redux-persist
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import AsyncStorage from '@react-native-async-storage/async-storage';
import ReduxThunk from 'redux-thunk';
// import rootReducer from "./reducers/assets"; // Root reducer
import rootReducer from './index';

const persistConfig = {
  // configuration object for redux-persist
  timeout: null,
  key: 'root',
  storage: AsyncStorage, // define which storage to use
};

const persistedReducer = persistReducer(persistConfig, rootReducer); // create a persisted reducer

const store = createStore(
  persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
  applyMiddleware(ReduxThunk) // add any middlewares here
);

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export { store, persistor };
