import React, { forwardRef, useRef } from 'react';
import { View, Text, StyleSheet, TextInput, SafeAreaView } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import InputSpinner from 'react-native-input-spinner';
import { mScale } from '../../../utils/myUtils';
import {
  globalStyles,
  defFontSize,
  defTitleFontSize,
} from '../../../styles/global';
// import { color, t } from 'react-native-tailwindcss';

const InputBox = forwardRef((props, ref) => {
  const {
    control,
    formState: { errors },
  } = props.form;
  // const { handleSubmit, control, errors } = useForm();
  const inputRef = ref ? ref : useRef();
  // console.log('errors-errors++', errors);
  // console.log('errors[props.name]', errors[props.name]);
  return (
    <SafeAreaView style={styles.inputFieldContainer}>
      <Controller
        name={props.name}
        control={control}
        rules={props.rules}
        // defaultValue=''
        defaultValue={props.defValue ? props.defValue : ''}
        onFocus={() => {
          inputRef.current.focus();
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          // console.log('props.isGroupCheckInAllowed', props);
          if (props.isSpinner && !props.isGroupCheckInAllowed) {
            return <View></View>;
          }

          return (
            <View style={{ flexDirection: 'column', alignItems: 'center' }}>
              <View
                style={
                  [
                    props.isSpinner
                      ? styles.inputLabelWideContainer
                      : styles.inputLabelContainer,
                  ]
                  // { backgroundColor: 'red' }
                }
              >
                <Text style={styles.inputLabel}>{props.field.label}</Text>
                {/* {console.log(
                'props.fieldCount',
                props.fieldCount,
                props.fieldIndex
              )} */}
              </View>
              <View
                style={
                  props.isSpinner
                    ? styles.inputSpinnerContainer
                    : styles.inputContainer
                }
              >
                {errors[props.name] && (
                  <View
                    style={{
                      height: '100%',
                      marginRight: 20,
                      position: 'absolute',
                      right: 0,
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: -999,
                    }}
                  >
                    <Text style={styles.errorText}>
                      {errors[props.name].message}
                    </Text>
                  </View>
                )}
                {!props.isSpinner && (
                  <TextInput
                    style={styles.inputField}
                    {...props}
                    // autoFocus={props.field.autoFocus}
                    ref={inputRef}
                    keyboardType={props.field.keyboardType}
                    // mode='outlined'
                    value={value}
                    autoCapitalize={'characters'}
                    returnKeyType={
                      props.fieldCount === props.fieldIndex + 1 ? 'go' : 'next'
                    }
                    // returnKeyType={'go'}
                    // autoCapitalize={'characters'}
                    // placeholder='xxxxx'
                    // showSoftInputOnFocus={true}
                    onChangeText={(val) => {
                      if (props.handleChange) {
                        onChange(props.handleChange(val));
                      } else onChange(val);
                    }}
                    onSubmitEditing={() => {
                      props.fieldCount === props.fieldIndex + 1
                        ? props.onSubmit()
                        : props.nextInput?.current?.focus();
                    }}
                  />
                )}
                {props.isSpinner && (
                  <InputSpinner
                    max={props.maxValue ? props.maxValue : 10}
                    min={1}
                    step={1}
                    // leftButtonProps={}
                    buttonStyle={{ width: mScale(50), height: mScale(50) }}
                    inputStyle={{ width: mScale(100) }}
                    // colorMax={'#f04048'}
                    // colorMin={'#40c5f4'}
                    // skin={'clean'}
                    fontSize={defFontSize}
                    style={styles.inputSpinnerField}
                    {...props}
                    // ref={inputRef}
                    keyboardType={props.field.keyboardType}
                    value={value}
                    // autoCapitalize={'characters'}
                    returnKeyType={
                      props.fieldCount === props.fieldIndex + 1 ? 'go' : 'next'
                    }
                    onChange={(val) => {
                      console.log('onChangeText', val);
                      onChange(val);
                    }}
                    onSubmitEditing={() => {
                      props.fieldCount === props.fieldIndex + 1
                        ? props.onSubmit()
                        : props.nextInput?.current?.focus();
                    }}
                  />
                )}
              </View>
            </View>
          );
        }}
      />
      {/* <View
        style={{
          // backgroundColor: 'blue',
          height: 10,
        }}
      > */}
      {/* {errors[props.name] && (
        <Text
          style={[
            styles.errorText,
            {
              bottom: 0,
            },
          ]}
        >
          {errors[props.name].message}
        </Text>
      )} */}
      {/* </View> */}
      {/* <Controller
        name={props.name}
        control={control}
        rules={{
          required: props.required,
          pattern: props.pattern ? props.pattern : null,
          maxLength: props.maxLength
            ? {
                value: props.maxLength,
                message: 'This input exceeds maximum character limit.',
              }
            : null,
        }}
        onFocus={() => {
          inputRef.current.focus();
        }}
        // initialValue={localLocDataRef.current.zipCode}
        // defaultValue='xxx'
        render={(props) => (
          <TextInput
            {...props}
            error={errors.zipCode}
            mode='outlined'
            ref={inputRef}
            onChangeText={(value) => {
              // setValue('zipCode', text);
              props.onChange(value);
            }}
          />
        )}
      /> */}
    </SafeAreaView>
  );
});

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    marginBottom: 5,
  },
  errorText: {
    // color: 'dimgray',
    fontSize: defFontSize,
    // fontWeight: '100',
    color: 'red',
    // alignItems: 'center',
    // backgroundColor: '#F5FCFF',
    // flex: 1,
    // justifyContent: 'center',
  },
  inputFieldContainer: {
    // flex: 1,
    alignSelf: 'center',
    paddingBottom: mScale(5),
    // backgroundColor: 'yellow',
    width: '100%',
    // height: 100,
  },
  inputContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: 'white',
  },
  inputSpinnerContainer: {
    width: mScale(220),
    // backgroundColor: 'white',
  },

  inputField: {
    flex: 1,
    // width: '100%',
    borderWidth: 2,
    borderRadius: 5,
    borderColor: 'lightgray',
    fontSize: defFontSize,
    fontFamily: 'poppins-extralight',
    fontWeight: 'bold',
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  inputSpinnerField: {
    // flex: 2,
    // borderWidth: 2,
    // borderRadius: 5,
    // borderColor: 'lightgray',
    fontSize: mScale(60),
    fontFamily: 'poppins-extralight',
    fontWeight: 'bold',
    paddingHorizontal: mScale(15),
    paddingBottom: mScale(10),
  },
  inputLabelWideContainer: {
    width: '100%',
    marginVertical: mScale(8),
    zIndex: 999,
  },
  inputLabelContainer: {
    width: '100%',
    marginVertical: mScale(8),
    zIndex: 999,
    // backgroundColor: 'red',
  },

  inputLabel: {
    // marginBottom: mScale(15),
    fontSize: defFontSize,
    fontFamily: 'poppins-regular',
    marginBottom: mScale(-10),
    // marginRight: 200,
    // padding: 100,
    textAlign: 'left',
    // backgroundColor: 'red',
    // flex: 1,
    // alignSelf: 'flex-end',
    // textTransform: ''
  },
});
// const styles = {
//   wrapper: [t.selfStretch, t.mB5],
//   input: [
//     t.h11,
//     t.border,
//     t.selfStretch,
//     t.p2,
//     t.borderGray500,
//     t.rounded,
//     t.textBase,
//     t.textGray700,
//   ],
//   errorText: [t.mT1, t.textRed500, t.absolute, t._bottom40],
// };

export default InputBox;
