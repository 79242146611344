import React, { Component, useState, useEffect } from 'react';
import { Animated, View } from 'react-native';

// export default class Blinkextends extends Component {

// }

const BlinkingView = (props) => {
  const [fadeAnimation, setFadeAnimation] = useState(new Animated.Value(1));

  useEffect(() => {
    if (props.isEnabled) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(fadeAnimation, {
            toValue: 0,
            duration: props.duration,
            useNativeDriver: true,
          }),
          Animated.timing(fadeAnimation, {
            toValue: 1,
            duration: props.duration,
            useNativeDriver: true,
          }),
        ]),
        {
          iterations: props.repeatCount,
        }
      ).start(() => {
        props.onEnd();
      });
    }
    // return () => {
    //   cleanup;
    // };
  }, [props]);

  // console.log('props.children', props.children);
  return (
    <Animated.View style={{ opacity: fadeAnimation, width: '100%' }}>
      {props.children}
    </Animated.View>
  );
};

export default BlinkingView;
