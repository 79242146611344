// import Asset from "../../models/assets";
// import { API, graphqlOperation } from "aws-amplify";

// import { listAssets } from "../../src/graphql/queries";
// import {
//   addAsset as addAsset_AWS,
//   deleteAsset as deleteAsset_AWS,
//   updateAsset as updateAsset_AWS,
// } from "../../src/graphql/mutations";
import AsyncStorage from '@react-native-async-storage/async-storage';

// import { API, graphqlOperation } from 'aws-amplify';
// import { listCompanys } from '../../src/graphql/queries';

export const DELETE_USER = 'DELETE_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_REGISTRATION_INFO = 'UPDATE_REGISTRATION_INFO';
export const SET_USER = 'SET_USER';

export const getCurrentUser = () => {
  return async (dispatch, getState) => {
    try {
      //console.log(asset);

      const jsonValue = await AsyncStorage.getItem('_currentUser');
      // console.log(jsonValue != null ? JSON.parse(jsonValue) : null);
      const userObj = jsonValue != null ? JSON.parse(jsonValue) : null;
      console.log(userObj);
      dispatch({
        type: UPDATE_USER,
        userData: userObj,
      });

      console.log('GET_USER SUCCESS!!!');
    } catch (err) {
      console.log(err);
      console.log('error in getting AsyncStorage userData');
      throw err;
    }
  };
};

// export const saveUser = (user) => {
//   return async (myDispatchFunc, getState) => {
//     try {
//       // console.log("saveUser-saveUser-saveUser");
//       // console.log(user);
//       // console.log(user["custom:registrationKey"]);
//       // console.log(user["custom:registrationKey"].trim());
//       const companyInfo = await API.graphql(
//         graphqlOperation(listCompanys, {
//           filter: {
//             registrationKey: { eq: user['custom:registrationKey'].trim() },
//           },
//           // limit: 1,
//         })
//       );
//       // console.log(companyInfo);
//       // console.log(companyInfo.data.listCompanys.items[0].registrationKey);
//       // console.log(companyInfo.data.listCompanys.items[0].id);
//       // const jsonValue = JSON.stringify(user);
//       // console.log(jsonValue);
//       if (companyInfo.data.listCompanys.items.length > 0) {
//         user.companyId = companyInfo.data.listCompanys.items[0].id;
//         user.companyName = companyInfo.data.listCompanys.items[0].companyName;
//       }
//       AsyncStorage.setItem('currentUser', JSON.stringify(user));
//       // console.log(user);
//       myDispatchFunc({
//         type: UPDATE_USER,
//         userData: user,
//       });

//       return companyInfo;
//     } catch (err) {
//       console.log(err);
//       console.log('error in updating AsyncStorage user');
//       throw err;
//     }
//   };
// };

// export const saveRegistrationInfo = (regInfo) => {
//   return async (myDispatchFunc, getState) => {
//     try {
//       AsyncStorage.setItem('registrationInfo', JSON.stringify(regInfo));
//       console.log('regInfo', regInfo);
//       myDispatchFunc({
//         type: UPDATE_REGISTRATION_INFO,
//         regInfo: regInfo,
//       });
//     } catch (err) {
//       console.log(err);
//       console.log('error in updating AsyncStorage registration info');
//       throw err;
//     }
//   };
// };
