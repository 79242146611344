import Colors from './colors';
import { Platform } from 'react-native';

export default {
  MAX_WIDTH: 700,
  SCALE_FACTOR: Platform.OS === 'web' ? 0.2 : 1,
  DEF_BACKGROUND_COLOR: Colors.lavenderWeb,
  DARK_BACKGROUND_COLOR: Colors.dark_background,
  // STD_SCREEN_HEIGHT: 1920,
};
