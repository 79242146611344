// import ASSETS from "../../data/dummy-data";
import {
  DELETE_GUEST,
  CREATE_GUEST,
  UPDATE_GUEST,
  CLEAR_GUEST,
  SET_GUEST,
  UPLOAD_PROGRESS,
} from '../actions/guest';
import Guest from '../../models/guest';

const initialStates = {
  currentGuest: {},
};

export default (state = initialStates, action) => {
  // console.log(JSON.stringify(state));
  // console.log(JSON.stringify(initialStates));

  switch (action.type) {
    case UPDATE_GUEST:
      return {
        currentGuest: { ...state.currentGuest, ...action?.guest },
      };

    case CREATE_GUEST:
      return {
        currentGuest: action?.guest,
      };

    case CLEAR_GUEST:
      return {
        currentGuest: {},
      };
    case DELETE_GUEST:
      // console.log(
      //   'DELETE_GUEST',
      //   action.guestId,
      //   state.currentGuest.guestWaitListId
      // );
      // if (action.guestId === state.currentGuest.guestWaitListId)
      return {
        currentGuest: {},
      };
  }
  // console.log(state);
  return state;
};
