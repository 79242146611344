// import Asset from "../../models/assets";
// import { API, graphqlOperation } from "aws-amplify";

// import { listAssets } from "../../src/graphql/queries";
// import {
//   addAsset as addAsset_AWS,
//   deleteAsset as deleteAsset_AWS,
//   updateAsset as updateAsset_AWS,
// } from "../../src/graphql/mutations";
// import { AsyncStorage } from "react-native";

// import { API, graphqlOperation } from 'aws-amplify';
// import { getCompany } from '../../src/graphql/queries';

// import * as customQueries from '../../src/graphql/myCustomQueries';
export const SHOW_CONFIRM_DLG = 'SHOW_CONFIRM_DLG';
export const HIDE_CONFIRM_DLG = 'HIDE_CONFIRM_DLG';
export const SAVE_APP_SETTINGS = 'SAVE_APP_SETTINGS';

export const showConfirmDlg = () => {
  return async (dispatch, getState) => {
    try {
      console.log('----------');
      dispatch({
        type: SHOW_CONFIRM_DLG,
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export const hideConfirmDlg = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: HIDE_CONFIRM_DLG,
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

// export const getAppSettings = (companyId) => {
//   return async (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         console.log('getAppSettings = (companyId)', companyId);
//         const getCompanyFromGraphQl = await API.graphql(
//           graphqlOperation(customQueries.getCompany, {
//             id: companyId,
//             authMode: 'AWS_IAM', //"AMAZON_COGNITO_USER_POOLS",
//           })
//         );
//         console.log('getCompanyFromGraphQl---', getCompanyFromGraphQl);
//         const result = getCompanyFromGraphQl?.data?.getCompany;
//         console.log('appSettings_', result?.appSettings);

//         dispatch({
//           type: SAVE_APP_SETTINGS,
//           appSettings: result.appSettings ? result.appSettings : {},
//         });

//         resolve(result.appSettings);
//       } catch (err) {
//         console.log(err);
//         console.log('error fetching appsetiings');
//         reject(err);
//       }
//     });
//   };
// };
