import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleSheet,
  View,
  Text,
  Image,
  FlatList,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { mScale, normalizeInput, convertMinToHrMin } from '../utils/myUtils';
import InputBox from '../components/UI/forms/InputBox';
import { Picker } from '@react-native-picker/picker';
import { useForm } from 'react-hook-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalStyles as styles } from '../styles/global';
import CheckInNotAvailable from '../components/UI/CheckInNotAvailable';

let fieldListData = [
  {
    name: 'fullName',
    label: 'Name',
    keyboardType: 'default',
    // isVisible: true,
    isSpinner: false,
    rules: {
      required: 'This is required.',
      //   pattern: props.pattern ? props.pattern : null,
      maxLength: 128
        ? {
            value: 128,
            message: 'This input exceeds maximum character limit.',
          }
        : null,
    },
  },
  {
    name: 'phoneNum',
    label: 'Phone',
    keyboardType: 'phone-pad',
    maxLength: 128,
    // isVisible: true,
    isSpinner: false,
    rules: {
      required: 'This is required.',
      //   pattern: props.pattern ? props.pattern : null,
      maxLength: 128
        ? {
            value: 128,
            message: 'This input exceeds maximum character limit.',
          }
        : null,
    },
  },
  {
    name: 'guestCount',
    label: 'Guest Count',
    keyboardType: 'phone-pad',
    maxLength: 128,
    // isVisible: true,
    isSpinner: true,
  },
];

// const template = {
//   title: 'Basic Form to Add Employee',
//   Fields: [
//     {
//       name: 'fullName',
//       type: 'text',
//       title: 'Name',
//       required: {
//         value: true,
//         message: 'Employee First Name is required',
//       },
//       validation: function (val) {
//         return val.length >= 5 || 'Min Length is 5';
//       },
//     },
//     {
//       name: 'lastName',
//       type: 'text',
//       title: 'Last Name',
//       required: {
//         value: true,
//         message: 'Employee Last Name is required',
//       },
//       validation: function (val) {
//         return val.length >= 5 || 'Min Length is 5';
//       },
//     },
//   ],
// };

// const defaultName = 'Plastic Card Systems';

// function getParameterByName(paramKey, url = window.location.href) {
//   console.log('getParameterByName', url);
//   name = name.replace(/[\[\]]/g, '\\$&');
//   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//     results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return '';
//   return decodeURIComponent(results[2].replace(/\+/g, ' '));
//   // var searchParams1 = new URLSearchParams(url);
// }

const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

// const qs = (a) => {
//   if (a == '') return {};
//   var b = {};
//   for (var i = 0; i < a.length; ++i) {
//     var p = a[i].split('=', 2);
//     if (p.length == 1) b[p[0]] = '';
//     else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
//   }
//   return b;
// }(window.location.search.substr(1).split('&'));

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

const MainScreen = (props) => {
  console.log('MainScreen props', props);
  const propsObj = props.route.params;
  var date = new Date();

  const dispatch = useDispatch();
  const [estWaitTime, setEstWaitTime] = useState('');
  const [fieldList, setFieldList] = useState(fieldListData);

  // let fieldList_ref = [];
  // fieldList.map((item, index) => {
  //   const item_ref = useRef();
  //   fieldList_ref.push(item_ref);
  // });

  const form = useForm({
    mode: 'onChange',
    // defaultValues: {
    //   userId: '',
    //   firstName: '',
    //   lastName: '',
    //   location: '',
    // },
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    reset,
  } = form;

  const onSubmit = async (formData) => {
    console.log('onSubmit', formData, props);

    props.navigation.push('NotifyMe', {
      ...propsObj,
      formData: formData,
    });

    // var lambda = new AWS.Lambda();

    // var name = xxxx;
    // var input;
    // if (name.value == null || name.value == '') {
    //   input = {};
    // } else {
    //   input = {
    //     name: name.value,
    //   };
    // }
    // lambda.invoke(
    //   {
    //     FunctionName: 'SaniSignSmsHandlerFunc-prod',
    //     Payload: JSON.stringify(input),
    //   },
    //   function (err, data) {}
    // );
  };

  const handleChange = (value) => {
    console.log('value', value);
    let normInput = normalizeInput(value, value.substring(1, 1));
    console.log('normalizeInput', normInput);
    return normInput;
  };

  useEffect(() => {
    console.log('MainScreen Props CHANGED!!!', props, propsObj);
    console.log('propsObj.resetForm', propsObj.resetForm);

    // if (propsObj.resetForm) {
    //   // setFieldList(fieldListData);
    //   reset({
    //     fullName: '',
    //     phoneNum: '',
    //     guestCount: 1,
    //   });
    // }

    if (propsObj.queueInfo)
      setEstWaitTime(
        propsObj.queueInfo?.waitTime.estMedWaitTime < 1
          ? '< 1 min.'
          : convertMinToHrMin(propsObj.queueInfo?.waitTime.estMedWaitTime)
      );
    else setEstWaitTime('< 1 min.');
  }, [props]);

  useEffect(() => {
    return () => {};
  }, []);

  if (!propsObj.isCheckInAvailable) {
    return <CheckInNotAvailable />;
  }

  if (!propsObj.queueSettings) {
    return (
      <View style={styles.container}>
        <View style={{ marginTop: mScale(0), alignItems: 'center' }}>
          <Image style={styles.icon} source={require('../assets/logo.png')} />
          <Text style={styles.title}>Invalid parameters</Text>
          <Text style={styles.infoText2}>
            Please check the URL and try again.
          </Text>
        </View>
      </View>
    );
  }

  console.log('propsObj.selectedService:', propsObj.selectedService);
  return (
    <View style={styles.container}>
      {!propsObj.selectedService && (
        <View style={{ marginTop: 0, alignItems: 'center' }}>
          <Image style={styles.icon} source={require('../assets/logo.png')} />
          <Text style={styles.title}>Welcome to</Text>
          <Text style={styles.title}>{propsObj.companyName}</Text>
        </View>
      )}
      {propsObj.selectedService && (
        <View style={{ marginTop: 20, alignItems: 'center' }}>
          <Text style={[styles.title, { color: 'green' }]}>
            {propsObj.selectedService.type.toUpperCase()}
          </Text>
          <Text style={styles.title}>Please check-in</Text>
        </View>
      )}
      <View
        style={{
          //   flex: 1,
          width: '100%',
          //   height: '100%',
          // backgroundColor: 'red',
          justifyContent: 'flex-start',
          marginVertical: mScale(10),
          //   alignItems: 'center',
          //   backgroundColor: appConst.DEF_BACKGROUND_COLOR,
        }}
      >
        {fieldList && (
          <KeyboardAwareScrollView>
            {fieldList.map((item, index) => {
              // console.log('index', index);
              return (
                <InputBox
                  key={index}
                  fieldIndex={index}
                  autoFocus={item.autoFocus}
                  name={item.name}
                  field={item}
                  rules={item.rules}
                  label={item.label}
                  isGroupCheckInAllowed={
                    propsObj.queueSettings?.isGroupCheckInAllowed
                  }
                  maxValue={propsObj.queueSettings?.maxGuestPerGroup}
                  isSpinner={item.isSpinner}
                  form={form}
                  ref={fieldList[index]}
                  defValue={
                    fieldList[index].defaultValue
                      ? fieldList[index].defaultValue
                      : ''
                  }
                  nextInput={fieldList[index + 1]}
                  returnKeyType='next'
                  fieldCount={fieldList.length}
                  handleChange={
                    fieldList[index].keyboardType === 'phone-pad'
                      ? handleChange
                      : null
                  }
                  onSubmit={handleSubmit(onSubmit)}
                  // onSubmitEditing={() => {
                  //   ref_lastName.current.focus();
                  // }}
                />
              );
            })}
          </KeyboardAwareScrollView>
        )}

        <View
          style={{
            alignItems: 'center',
            margin: 0,
            // height: mScale(180),
          }}
        >
          {estWaitTime !== '' && (
            <View
              style={{
                alignItems: 'center',
                margin: mScale(0),
                // height: mScale(180),
              }}
            >
              <Text style={styles.medText}>
                Estimated wait time {'\n'} as of{' '}
                {date.toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                {' is '}
                <Text>
                  {!propsObj.queueInfo ||
                  propsObj.queueInfo?.waitTime.estMedWaitTime < 1
                    ? ' < 1 min.'
                    : convertMinToHrMin(
                        propsObj.queueInfo?.waitTime.estMedWaitTime
                      )}
                </Text>
              </Text>
              {/* <Text style={styles.medText}>{estWaitTime}</Text> */}
            </View>
          )}
          <TouchableOpacity
            style={styles.button}
            onPress={handleSubmit(onSubmit)}
          >
            <Text style={styles.buttonLabel}>Check Me In</Text>
          </TouchableOpacity>
          {propsObj.queueSettings.welcomeMessage &&
            propsObj.queueSettings.welcomeMessage.length > 0 && (
              <Text style={[styles.smallText]}>
                {propsObj.queueSettings.welcomeMessage}
              </Text>
            )}
          {propsObj.selectedService && (
            <TouchableOpacity
              style={{ alignItems: 'center', marginTop: mScale(20) }}
              onPress={() => {
                // props.navigation.goBack();
                props.navigation.reset({
                  index: 0,
                  routes: [{ name: 'Home' }],
                });
                props.navigation.push('Services', { resetForm: false });
              }}
            >
              <Text style={styles.medUndelinedText}>Go to previous screen</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};

export default MainScreen;
