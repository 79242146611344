//@ts-check
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Dimensions,
  Image,
} from 'react-native';
import React, { useEffect, useRef } from 'react';
import { mScale, toISOLocal, validateInput } from '../../utils/myUtils';

const CheckInNotAvailable = (props) => {
  return (
    <View style={styles.container2}>
      <View style={styles.closed}>
        <View>
          <Image
            style={styles.image}
            resizeMode={'contain'}
            //@ts-ignore
            source={require('../../assets/closed.png')}
          />
        </View>
        {/* <GetServiceStatLogo
            {...props}
            // appProps={propsObj}
            //@ts-ignore
            // isDlgShowing={true}
            imageSource={require('../assets/logo.png')}
          /> */}
        <Text style={styles.title}>Online check-in is currently closed.</Text>
        <Text style={styles.infoText2}>
          Please try again during business hours.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  // outerContainer: {
  //   flex: 1,
  //   width: deviceWidth,
  //   backgroundColor: Colors.defBackgroundColor,
  // },
  // innerContainer: {
  //   flex: 1,
  //   width: 600,
  //   maxWidth: deviceWidth,
  //   height: '100%',
  //   alignSelf: 'center',
  //   backgroundColor: 'red',
  // },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container2: {
    flex: 1,
    backgroundColor: '#F5F5F5',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 18,
    // alignSelf: 'center'
  },
  closed: {
    marginTop: 40,
    alignItems: 'center',
    width: '100%',
    // backgroundColor: 'red',
  },
  image: {
    width: 300,
    height: 180,
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginBottom: 10,
    // backgroundColor: 'red',
  },
  title: {
    fontSize: mScale(32),
    textAlign: 'center',
    // fontWeight: 'bold',
    color: 'black',
    fontFamily: 'poppins-regular',
    // width: '100%',
    // marginBottom: mScale(-50),
  },
  infoText2: {
    fontSize: mScale(22),
    fontFamily: 'poppins-light',
    // marginBottom: -2,
    // marginRight: 10,
    color: 'black',
    textAlign: 'center',
    width: '100%',
  },
});

export default CheckInNotAvailable;
