//https://dev.to/bhatvikrant/redux-persist-v6-in-detail-react-10nh

// Imports: Dependencies
import { combineReducers } from 'redux';
// Imports: Reducers
import usersReducer from './reducers/users';
import appReducer from './reducers/app';
import guestReducer from './reducers/guest';

// Redux: Root Reducer
const rootReducer = combineReducers({
  guestReducer: guestReducer,
  usersReducer: usersReducer,
  appReducer: appReducer,
});

// Exports
export default rootReducer;
