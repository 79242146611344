// import Storage from '@aws-amplify/storage';
// import { reject } from 'core-js/fn/promise';
// import { Image } from 'react-native';
// import moment, { now } from 'moment';
import { Dimensions, Image } from 'react-native';
import {
  scale,
  verticalScale,
  moderateScale,
  moderateVerticalScale,
  s,
  vs,
  ms,
  mvs,
} from 'react-native-size-matters/extend';
import appConst from '../constants/appConst';

// const isNumericInput = (event) => {
//   const key = event.keyCode;
//   return (
//     (key >= 48 && key <= 57) || // Allow number line
//     (key >= 96 && key <= 105) // Allow number pad
//   );
// };

// const isModifierKey = (event) => {
//   const key = event.keyCode;
//   return (
//     event.shiftKey === true ||
//     key === 35 ||
//     key === 36 || // Allow Shift, Home, End
//     key === 8 ||
//     key === 9 ||
//     key === 13 ||
//     key === 46 || // Allow Backspace, Tab, Enter, Delete
//     (key > 36 && key < 41) || // Allow left, up, right, down
//     // Allow Ctrl/Command + A,C,V,X,Z
//     ((event.ctrlKey === true || event.metaKey === true) &&
//       (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
//   );
// };

// export const enforceFormat = (event) => {
//   // Input must be of a valid number format or a modifier key, and not longer than ten digits
//   if (!isNumericInput(event) && !isModifierKey(event)) {
//     event.preventDefault();
//   }
// };

// export const formatToPhone = (event) => {
//   if (isModifierKey(event)) {
//     return;
//   }

//   const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
//   const areaCode = input.substring(0, 3);
//   const middle = input.substring(3, 6);
//   const last = input.substring(6, 10);

//   if (input.length > 6) {
//     event.target.value = `(${areaCode}) ${middle} - ${last}`;
//   } else if (input.length > 3) {
//     event.target.value = `(${areaCode}) ${middle}`;
//   } else if (input.length > 0) {
//     event.target.value = `(${areaCode}`;
//   }
// };

const deviceDisplay = Dimensions.get('window');

export const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

export const validateInput = (value) => {
  let error = '';

  if (!value) error = 'Required!';
  else if (value.length !== 14)
    error = 'Invalid phone format. ex: (555) 555-5555';

  return error;
};

export const compare = (a, b) => {
  const bandA = a.band.toUpperCase();
  const bandB = b.band.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
};

export const getAveFromArray = (objs, numProp) => {
  // console.log('objs', objs);
  // console.log('numProp', numProp);
  let items = objs.map((a) => a[numProp]);
  let result = 0;
  // console.log('items', items, numProp, objs[0][numProp]);
  if (items.length > 1) {
    // console.log('1111111');
    const mid = Math.floor(items.length / 2),
      nums = [...items].sort((a, b) => a - b);
    result =
      items.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  } else if (items.length === 1) {
    // console.log('222222');
    result = items[0];
  }
  console.log('average', result);
  return result;
};

export const mScale = (val) => {
  const scale =
    deviceDisplay.width < 768
      ? 1
      : deviceDisplay.width >= 768 && deviceDisplay.width <= 1024
      ? 0.5
      : 0.2; //appConst.SCALE_FACTOR;
  const calcVal = mvs(val, scale); //vs(val); //
  // console.log('mScale', val, scale, calcVal);
  return calcVal;
  // return val;
};

export const convertMinToHrMin = (n) => {
  if (n > 60) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    let hrWord = 'hr';
    let minWord = 'min';
    if (rhours > 1) hrWord = 'hrs';
    if (rminutes > 1) minWord = 'mins';
    return rhours + ' ' + hrWord + ' and ' + rminutes + ' ' + minWord + '.';
  } else {
    let minWord = 'min';
    if (n > 1) minWord = 'mins';
    return n + ' ' + minWord + '.';
  }
};

export const toISOLocal = (d, timeZone = 'America/New_York') => {
  let localDate = new Date().toLocaleString('en-CA', {
    timeZone: 'America/New_York',
  });

  return localDate;
  // const z = (n) => ('0' + n).slice(-2);
  // let off = d.getTimezoneOffset();
  // const sign = off < 0 ? '+' : '-';
  // off = Math.abs(off);
  // return (
  //   new Date(d.getTime() - d.getTimezoneOffset() * 60000)
  //     .toISOString()
  //     .slice(0, -1) +
  //   sign +
  //   z((off / 60) | 0) +
  //   ':' +
  //   z(off % 60)
  // );
};
export const removeEmptyOrNull = (obj) => {
  // Object.keys(obj).forEach(
  //   (k) =>
  //     (obj[k] && typeof obj[k] === 'object' && removeEmptyOrNull(obj[k])) ||
  //     (!obj[k] && obj[k] !== undefined && delete obj[k])
  // );
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeEmptyOrNull(val)) ||
      ((val === null || val === '') && delete obj[key])
  );
  return obj;
};

// export const convertDateTimeToISOString = (obj) => {
//   Object.entries(obj).forEach(([key, val]) => {
//     // if (val && typeof val === 'object') convertDateTimeToISOString(val);
//     // else
//     if (val && typeof val === 'object' && moment(val).isValid()) {
//       console.log('==================');
//       obj[key] = moment(val).format();
//     }
//   });
//   return obj;
// };
// export const convertISOstringToUTC = (val) => {
//   console.log(
//     'moment(val).utc()._d',
//     val,
//     moment(val).isValid() ? moment(val).utc()._d : null
//   );
//   return moment(val).isValid() ? moment(val).utc()._d : null;
// };

export const getImageStyleBasedOnImageUri = (assetsUri, itemWidth) => {
  return [
    {
      flex: 1,
      borderWidth: 0,
      width: itemWidth,
      height: assetsUri.data ? itemWidth * assetsUri.data.ratio : itemWidth, //getImageHeight(assetsUri, screenWidth),
    },
  ];
};

export const mySortByValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export function convertUTCDateToLocalDate(dateStr) {
  console.log(dateStr);
  let utcDate = new Date(dateStr);
  var newDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
  );
  console.log(newDate);
  return newDate.toLocaleDateString(); // + " " + newDate.toLocaleTimeString();
}

// const getThumbnail = (assetUri) => {
//   return new Promise((resolve, reject) => {
//     let s3key = '';
//     if (assetUri.data) s3key = assetUri.data.thumbnail.key;
//     else s3key = assetUri.thumbnail.key;

//     Storage.get(s3key, {
//       level: 'public',
//       expires: 604800, //One week
//     })
//       .then((result) => {
//         // console.log(result);
//         assetUri.uri = result;
//         // asset.dimensions = {
//         //   width: 120, //asset.data.thumbnail.width,
//         //   height: 60, //asset.data.thumbnail.height,
//         // };
//         // asset.source.uri = result;
//         // availableAssetsUri[item].uri = result;

//         // console.log("0909090909", asset);
//         resolve(assetUri);
//       })
//       .catch((err) => reject(assetUri.id + ' - Error: ' + err));
//   });
// };

// export const downloadThumbnails = (availableAssetsUri) => {
//   return new Promise((resolve, reject) => {
//     // delete availableAssetsUri.uri;
//     // Promise.all(
//     const getThumbnailPromises = [];
//     availableAssetsUri.map(async (assetUri) => {
//       getThumbnailPromises.push(getThumbnail(assetUri));
//       // try {
//       //   if (asset.data.thumbnail)
//       //     await Storage.get(asset.data.thumbnail.key, {
//       //       // level: "public",
//       //       expires: 60,
//       //     })
//       //       .then((result) => {
//       //         console.log(result);
//       //         asset.uri = result;
//       //         // asset.dimensions = {
//       //         //   width: 120, //asset.data.thumbnail.width,
//       //         //   height: 60, //asset.data.thumbnail.height,
//       //         // };
//       //         // asset.source.uri = result;
//       //         // availableAssetsUri[item].uri = result;

//       //         console.log("0909090909", asset);
//       //         Promise.resolve;
//       //       })
//       //       .catch((err) => console.log(err));
//       // } catch (error) {
//       //   console.log(error);
//       //   Promise.reject;
//       // }
//     });
//     // ).then(() => {
//     //   console.log("1xxxxxxxxx", availableAssetsUri);
//     //   resolve();
//     // });
//     // console.log(getThumbnailPromises.length);
//     // if (getThumbnailPromises.length == 0) Promise.resolve(1);

//     // console.log("xxxxxxx");
//     // Promise.all(getThumbnailPromises)
//     Promise.allSettled(getThumbnailPromises)
//       .then((results) => {
//         // console.log(results);
//         resolve(0);
//       })
//       .catch((err) => {
//         console.log(err);
//         reject(err);
//       });
//   });
//   //for (const item in availableAssetsUri) {

//   //}
// };

export const getImageDimensions = (uri) => {
  return new Promise(function (resolved, reject) {
    Image.getSize(
      uri,
      (width, height) => {
        resolved({ width: width, height: height });
      },
      (error) => {
        console.error(`Couldn't get the image size: ${error}`);
        reject("Couldn't get the image size.");
      }
    );
  });
};

export const getImageHeight = (assetUri, screenWidth) => {
  let w = 0;
  let h = 0;
  if (false) {
    //assetUri.data.thumbnail
    w = assetUri.data.thumbnail.width;
    h = assetUri.data.thumbnail.height;
    console.log(w, h);
  } else {
    Image.getSize(
      assetUri.uri,
      (width, height) => {
        w = width;
        h = height;
        console.log(w, h);
        const ratio = h / w;
        return screenWidth * ratio;
      },
      (error) => {
        console.error(`Couldn't get the image size: ${error.message}`);
        return 100;
      }
    );
  }
};

// export const getImageAspectRatio = (uri) => {
//   let w = 0;
//   let h = 0;

//   Image.getSize(
//     uri,
//     (width, height) => {
//       w = width;
//       h = height;
//       console.log(w, h);
//       const ratio = h / w;
//       console.log(ratio);
//       return ratio;
//     },
//     (error) => {
//       console.error(`Couldn't get the image size: ${error.message}`);
//       return 100;
//     }
//   ).then((ratio) => {
//     console.log(ratio);
//   });
// };

export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');
};

export const getNumPeopleAheadMsg = (
  peopleAheadCount,
  numAheadToBeNotified,
  sequenceInfo,
  currentGuestTicketNumber
) => {
  let peopleAheadMsg =
    'There are ' + peopleAheadCount + ' people ahead of you.';
  if (peopleAheadCount === 1)
    peopleAheadMsg =
      'There is ' +
      peopleAheadCount +
      ' person ahead of you.\nPlease be prepared for your turn.';
  else if (peopleAheadCount === 0)
    peopleAheadMsg =
      'There is no one ahead of you.\nPlease be prepared for your turn.';
  // else 'There is no one ahead of you. Please be prepared for your turn.';

  if (
    peopleAheadCount > 0 &&
    peopleAheadCount < numAheadToBeNotified &&
    sequenceInfo.currentTicketNumber !== currentGuestTicketNumber
  ) {
    peopleAheadMsg =
      'Ticket No. ' +
      sequenceInfo.currentTicketNumber +
      ' is now being served.\nPlease be prepared for your turn.';
  }

  console.log(
    'getNumPeopleAheadMsg',
    peopleAheadCount,
    numAheadToBeNotified,
    sequenceInfo.currentTicketNumber,
    peopleAheadMsg,
    currentGuestTicketNumber
  );

  return peopleAheadMsg;
};
