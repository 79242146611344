/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateTextToSpeechByCompanyBranchCode = /* GraphQL */ `
  subscription OnUpdateTextToSpeechByCompanyBranchCode(
    $companyCode: String!
    $branchCode: String!
  ) {
    onUpdateTextToSpeechByCompanyBranchCode(
      companyCode: $companyCode
      branchCode: $branchCode
    ) {
      id
      companyId
      companyCode
      branchCode
      ticketNumber
      location
      createdAt
      updatedAt
      s3Bucket
      s3Uri
      s3SignedUrl
      s3Filename
      readCount
      audioMode
      speechSpeed
    }
  }
`;
export const onCreateTextToSpeechByCompanyBranchCode = /* GraphQL */ `
  subscription OnCreateTextToSpeechByCompanyBranchCode(
    $companyCode: String!
    $branchCode: String!
  ) {
    onCreateTextToSpeechByCompanyBranchCode(
      companyCode: $companyCode
      branchCode: $branchCode
    ) {
      id
      companyId
      companyCode
      branchCode
      ticketNumber
      location
      createdAt
      updatedAt
      s3Bucket
      s3Uri
      s3SignedUrl
      s3Filename
      readCount
      audioMode
      speechSpeed
    }
  }
`;
export const onUpdateGuestSequenceNumberByCustomId = /* GraphQL */ `
  subscription OnUpdateGuestSequenceNumberByCustomId($id: String!) {
    onUpdateGuestSequenceNumberByCustomId(id: $id) {
      id
      sequence_number
      waitTime
      waitList
      currentTicketId
      currentTicketNumber
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWaitListByGuestId = /* GraphQL */ `
  subscription OnUpdateWaitListByGuestId($id: ID!) {
    onUpdateWaitListByGuestId(id: $id) {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onDeleteWaitListByGuestId = /* GraphQL */ `
  subscription OnDeleteWaitListByGuestId($id: ID!) {
    onDeleteWaitListByGuestId(id: $id) {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onCreateWaitListByCompanyCode = /* GraphQL */ `
  subscription OnCreateWaitListByCompanyCode($companyCode: String!) {
    onCreateWaitListByCompanyCode(companyCode: $companyCode) {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onUpdateWaitListByCompanyCode = /* GraphQL */ `
  subscription OnUpdateWaitListByCompanyCode($companyCode: String!) {
    onUpdateWaitListByCompanyCode(companyCode: $companyCode) {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onDeleteWaitListByCompanyCode = /* GraphQL */ `
  subscription OnDeleteWaitListByCompanyCode($companyCode: String!) {
    onDeleteWaitListByCompanyCode(companyCode: $companyCode) {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onCreateWaitListByCompanyId = /* GraphQL */ `
  subscription OnCreateWaitListByCompanyId($companyId: ID!) {
    onCreateWaitListByCompanyId(companyId: $companyId) {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onUpdateWaitListByCompanyId = /* GraphQL */ `
  subscription OnUpdateWaitListByCompanyId($companyId: ID!) {
    onUpdateWaitListByCompanyId(companyId: $companyId) {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onDeleteWaitListByCompanyId = /* GraphQL */ `
  subscription OnDeleteWaitListByCompanyId($companyId: ID!) {
    onDeleteWaitListByCompanyId(companyId: $companyId) {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onUpdateDeviceById = /* GraphQL */ `
  subscription OnUpdateDeviceById($id: ID!) {
    onUpdateDeviceById(id: $id) {
      id
      name
      model
      serialNo
      location
      companyId
      type
      registrationKey
      albumId
      uri
      imageRatio
      assignedAssets
      lastConnectionAt
      lastUpdatedAt
      deviceInfo {
        installationId
        deviceName
        deviceYearClass
        platform
        brand
        manufacturer
        modelName
        modelId
        productName
        totalMemory
        osName
        osVersion
        platformApiLevel
      }
      isNameShowing
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteDeviceById = /* GraphQL */ `
  subscription OnDeleteDeviceById($id: ID!) {
    onDeleteDeviceById(id: $id) {
      id
      name
      model
      serialNo
      location
      companyId
      type
      registrationKey
      albumId
      uri
      imageRatio
      assignedAssets
      lastConnectionAt
      lastUpdatedAt
      deviceInfo {
        installationId
        deviceName
        deviceYearClass
        platform
        brand
        manufacturer
        modelName
        modelId
        productName
        totalMemory
        osName
        osVersion
        platformApiLevel
      }
      isNameShowing
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateAssetByRegistrationKey = /* GraphQL */ `
  subscription OnUpdateAssetByRegistrationKey($registrationKey: String!) {
    onUpdateAssetByRegistrationKey(registrationKey: $registrationKey) {
      id
      name
      fileName
      description
      uriId
      type
      mimeType
      companyId
      companyCode
      registrationKey
      isEnabled
      isVisible
      duration
      runningTime
      fileSize
      displayOrder
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      height
      width
      ratio
      s3FilePath
      s3Bucket
      isTemplate
      templateUrl
      fileLastModified
      owner
      transactionID
      uri
      signedFullSizeUrl
      resizeMode
      createdAt
      updatedAt
      company {
        id
        licenseNo
        registrationKey
        env
        companyCode
        services
        isMultiService
        timeZone
        companyName
        address
        domain
        registeredDate
        contactPerson
        maxDevice
        maxCheckInDevice
        queueSettings
        appSettings
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      albums {
        items {
          id
          albumId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      devices {
        items {
          id
          deviceId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateTickerByRegistrationKey = /* GraphQL */ `
  subscription OnCreateTickerByRegistrationKey($registrationKey: String!) {
    onCreateTickerByRegistrationKey(registrationKey: $registrationKey) {
      id
      message
      companyId
      registrationKey
      fontColor
      backgroundColor
      fontSize
      speed
      isEnabled
      transactionID
      duration
      startDateTime
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateTickerByRegistrationKey = /* GraphQL */ `
  subscription OnUpdateTickerByRegistrationKey($registrationKey: String!) {
    onUpdateTickerByRegistrationKey(registrationKey: $registrationKey) {
      id
      message
      companyId
      registrationKey
      fontColor
      backgroundColor
      fontSize
      speed
      isEnabled
      transactionID
      duration
      startDateTime
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateWeatherByRegistrationKey = /* GraphQL */ `
  subscription OnCreateWeatherByRegistrationKey($registrationKey: String!) {
    onCreateWeatherByRegistrationKey(registrationKey: $registrationKey) {
      id
      isDateTimeVisible
      isDailyVisible
      isHourlyVisible
      style
      widgetPosition
      gradientColors
      isAnimated
      widgetHeight
      dateTimeFormat
      fontColor
      iconColor
      isEnabled
      transactionID
      companyId
      registrationKey
      openWEatherAPIkey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateWeatherByRegistrationKey = /* GraphQL */ `
  subscription OnUpdateWeatherByRegistrationKey($registrationKey: String!) {
    onUpdateWeatherByRegistrationKey(registrationKey: $registrationKey) {
      id
      isDateTimeVisible
      isDailyVisible
      isHourlyVisible
      style
      widgetPosition
      gradientColors
      isAnimated
      widgetHeight
      dateTimeFormat
      fontColor
      iconColor
      isEnabled
      transactionID
      companyId
      registrationKey
      openWEatherAPIkey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateDeviceByRegistrationKey = /* GraphQL */ `
  subscription OnCreateDeviceByRegistrationKey($registrationKey: String!) {
    onCreateDeviceByRegistrationKey(registrationKey: $registrationKey) {
      id
      name
      model
      serialNo
      location
      companyId
      type
      registrationKey
      albumId
      uri
      imageRatio
      assignedAssets
      lastConnectionAt
      lastUpdatedAt
      deviceInfo {
        installationId
        deviceName
        deviceYearClass
        platform
        brand
        manufacturer
        modelName
        modelId
        productName
        totalMemory
        osName
        osVersion
        platformApiLevel
      }
      isNameShowing
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteDeviceByRegistrationKey = /* GraphQL */ `
  subscription OnDeleteDeviceByRegistrationKey($registrationKey: String!) {
    onDeleteDeviceByRegistrationKey(registrationKey: $registrationKey) {
      id
      name
      model
      serialNo
      location
      companyId
      type
      registrationKey
      albumId
      uri
      imageRatio
      assignedAssets
      lastConnectionAt
      lastUpdatedAt
      deviceInfo {
        installationId
        deviceName
        deviceYearClass
        platform
        brand
        manufacturer
        modelName
        modelId
        productName
        totalMemory
        osName
        osVersion
        platformApiLevel
      }
      isNameShowing
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteAssetByRegistrationKey = /* GraphQL */ `
  subscription OnDeleteAssetByRegistrationKey($registrationKey: String!) {
    onDeleteAssetByRegistrationKey(registrationKey: $registrationKey) {
      id
      name
      fileName
      description
      uriId
      type
      mimeType
      companyId
      companyCode
      registrationKey
      isEnabled
      isVisible
      duration
      runningTime
      fileSize
      displayOrder
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      height
      width
      ratio
      s3FilePath
      s3Bucket
      isTemplate
      templateUrl
      fileLastModified
      owner
      transactionID
      uri
      signedFullSizeUrl
      resizeMode
      createdAt
      updatedAt
      company {
        id
        licenseNo
        registrationKey
        env
        companyCode
        services
        isMultiService
        timeZone
        companyName
        address
        domain
        registeredDate
        contactPerson
        maxDevice
        maxCheckInDevice
        queueSettings
        appSettings
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      albums {
        items {
          id
          albumId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      devices {
        items {
          id
          deviceId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCompanyByRegistrationKey = /* GraphQL */ `
  subscription OnUpdateCompanyByRegistrationKey($registrationKey: String!) {
    onUpdateCompanyByRegistrationKey(registrationKey: $registrationKey) {
      id
      licenseNo
      registrationKey
      env
      companyCode
      services
      isMultiService
      timeZone
      companyName
      address
      domain
      registeredDate
      contactPerson
      maxDevice
      maxCheckInDevice
      queueSettings
      appSettings
      createdAt
      updatedAt
      devices {
        items {
          id
          name
          model
          serialNo
          location
          companyId
          type
          registrationKey
          albumId
          uri
          imageRatio
          assignedAssets
          lastConnectionAt
          lastUpdatedAt
          isNameShowing
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      licenseNo
      registrationKey
      env
      companyCode
      services
      isMultiService
      timeZone
      companyName
      address
      domain
      registeredDate
      contactPerson
      maxDevice
      maxCheckInDevice
      queueSettings
      appSettings
      createdAt
      updatedAt
      devices {
        items {
          id
          name
          model
          serialNo
          location
          companyId
          type
          registrationKey
          albumId
          uri
          imageRatio
          assignedAssets
          lastConnectionAt
          lastUpdatedAt
          isNameShowing
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      licenseNo
      registrationKey
      env
      companyCode
      services
      isMultiService
      timeZone
      companyName
      address
      domain
      registeredDate
      contactPerson
      maxDevice
      maxCheckInDevice
      queueSettings
      appSettings
      createdAt
      updatedAt
      devices {
        items {
          id
          name
          model
          serialNo
          location
          companyId
          type
          registrationKey
          albumId
          uri
          imageRatio
          assignedAssets
          lastConnectionAt
          lastUpdatedAt
          isNameShowing
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      licenseNo
      registrationKey
      env
      companyCode
      services
      isMultiService
      timeZone
      companyName
      address
      domain
      registeredDate
      contactPerson
      maxDevice
      maxCheckInDevice
      queueSettings
      appSettings
      createdAt
      updatedAt
      devices {
        items {
          id
          name
          model
          serialNo
          location
          companyId
          type
          registrationKey
          albumId
          uri
          imageRatio
          assignedAssets
          lastConnectionAt
          lastUpdatedAt
          isNameShowing
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      name
      model
      serialNo
      location
      companyId
      type
      registrationKey
      albumId
      uri
      imageRatio
      assignedAssets
      lastConnectionAt
      lastUpdatedAt
      deviceInfo {
        installationId
        deviceName
        deviceYearClass
        platform
        brand
        manufacturer
        modelName
        modelId
        productName
        totalMemory
        osName
        osVersion
        platformApiLevel
      }
      isNameShowing
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      name
      model
      serialNo
      location
      companyId
      type
      registrationKey
      albumId
      uri
      imageRatio
      assignedAssets
      lastConnectionAt
      lastUpdatedAt
      deviceInfo {
        installationId
        deviceName
        deviceYearClass
        platform
        brand
        manufacturer
        modelName
        modelId
        productName
        totalMemory
        osName
        osVersion
        platformApiLevel
      }
      isNameShowing
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      name
      model
      serialNo
      location
      companyId
      type
      registrationKey
      albumId
      uri
      imageRatio
      assignedAssets
      lastConnectionAt
      lastUpdatedAt
      deviceInfo {
        installationId
        deviceName
        deviceYearClass
        platform
        brand
        manufacturer
        modelName
        modelId
        productName
        totalMemory
        osName
        osVersion
        platformApiLevel
      }
      isNameShowing
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateDeviceMessage = /* GraphQL */ `
  subscription OnCreateDeviceMessage {
    onCreateDeviceMessage {
      id
      name
      companyId
      Message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateDeviceMessage = /* GraphQL */ `
  subscription OnUpdateDeviceMessage {
    onUpdateDeviceMessage {
      id
      name
      companyId
      Message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteDeviceMessage = /* GraphQL */ `
  subscription OnDeleteDeviceMessage {
    onDeleteDeviceMessage {
      id
      name
      companyId
      Message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateDeviceAsset = /* GraphQL */ `
  subscription OnCreateDeviceAsset {
    onCreateDeviceAsset {
      id
      deviceId
      assetId
      createdAt
      updatedAt
      owner
      asset {
        id
        name
        fileName
        description
        uriId
        type
        mimeType
        companyId
        companyCode
        registrationKey
        isEnabled
        isVisible
        duration
        runningTime
        fileSize
        displayOrder
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
        height
        width
        ratio
        s3FilePath
        s3Bucket
        isTemplate
        templateUrl
        fileLastModified
        owner
        transactionID
        uri
        signedFullSizeUrl
        resizeMode
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        albums {
          nextToken
        }
        devices {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDeviceAsset = /* GraphQL */ `
  subscription OnUpdateDeviceAsset {
    onUpdateDeviceAsset {
      id
      deviceId
      assetId
      createdAt
      updatedAt
      owner
      asset {
        id
        name
        fileName
        description
        uriId
        type
        mimeType
        companyId
        companyCode
        registrationKey
        isEnabled
        isVisible
        duration
        runningTime
        fileSize
        displayOrder
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
        height
        width
        ratio
        s3FilePath
        s3Bucket
        isTemplate
        templateUrl
        fileLastModified
        owner
        transactionID
        uri
        signedFullSizeUrl
        resizeMode
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        albums {
          nextToken
        }
        devices {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDeviceAsset = /* GraphQL */ `
  subscription OnDeleteDeviceAsset {
    onDeleteDeviceAsset {
      id
      deviceId
      assetId
      createdAt
      updatedAt
      owner
      asset {
        id
        name
        fileName
        description
        uriId
        type
        mimeType
        companyId
        companyCode
        registrationKey
        isEnabled
        isVisible
        duration
        runningTime
        fileSize
        displayOrder
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
        height
        width
        ratio
        s3FilePath
        s3Bucket
        isTemplate
        templateUrl
        fileLastModified
        owner
        transactionID
        uri
        signedFullSizeUrl
        resizeMode
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        albums {
          nextToken
        }
        devices {
          nextToken
        }
      }
    }
  }
`;
export const onCreateAlbum = /* GraphQL */ `
  subscription OnCreateAlbum {
    onCreateAlbum {
      id
      name
      companyId
      uri
      createdAt
      updatedAt
      company {
        id
        licenseNo
        registrationKey
        env
        companyCode
        services
        isMultiService
        timeZone
        companyName
        address
        domain
        registeredDate
        contactPerson
        maxDevice
        maxCheckInDevice
        queueSettings
        appSettings
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      devices {
        items {
          id
          name
          model
          serialNo
          location
          companyId
          type
          registrationKey
          albumId
          uri
          imageRatio
          assignedAssets
          lastConnectionAt
          lastUpdatedAt
          isNameShowing
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      assets {
        items {
          id
          albumId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAlbum = /* GraphQL */ `
  subscription OnUpdateAlbum {
    onUpdateAlbum {
      id
      name
      companyId
      uri
      createdAt
      updatedAt
      company {
        id
        licenseNo
        registrationKey
        env
        companyCode
        services
        isMultiService
        timeZone
        companyName
        address
        domain
        registeredDate
        contactPerson
        maxDevice
        maxCheckInDevice
        queueSettings
        appSettings
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      devices {
        items {
          id
          name
          model
          serialNo
          location
          companyId
          type
          registrationKey
          albumId
          uri
          imageRatio
          assignedAssets
          lastConnectionAt
          lastUpdatedAt
          isNameShowing
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      assets {
        items {
          id
          albumId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAlbum = /* GraphQL */ `
  subscription OnDeleteAlbum {
    onDeleteAlbum {
      id
      name
      companyId
      uri
      createdAt
      updatedAt
      company {
        id
        licenseNo
        registrationKey
        env
        companyCode
        services
        isMultiService
        timeZone
        companyName
        address
        domain
        registeredDate
        contactPerson
        maxDevice
        maxCheckInDevice
        queueSettings
        appSettings
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      devices {
        items {
          id
          name
          model
          serialNo
          location
          companyId
          type
          registrationKey
          albumId
          uri
          imageRatio
          assignedAssets
          lastConnectionAt
          lastUpdatedAt
          isNameShowing
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      assets {
        items {
          id
          albumId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateAlbumAsset = /* GraphQL */ `
  subscription OnCreateAlbumAsset {
    onCreateAlbumAsset {
      id
      albumId
      assetId
      createdAt
      updatedAt
      album {
        id
        name
        companyId
        uri
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        devices {
          nextToken
        }
        assets {
          nextToken
        }
      }
      owner
      asset {
        id
        name
        fileName
        description
        uriId
        type
        mimeType
        companyId
        companyCode
        registrationKey
        isEnabled
        isVisible
        duration
        runningTime
        fileSize
        displayOrder
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
        height
        width
        ratio
        s3FilePath
        s3Bucket
        isTemplate
        templateUrl
        fileLastModified
        owner
        transactionID
        uri
        signedFullSizeUrl
        resizeMode
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        albums {
          nextToken
        }
        devices {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateAlbumAsset = /* GraphQL */ `
  subscription OnUpdateAlbumAsset {
    onUpdateAlbumAsset {
      id
      albumId
      assetId
      createdAt
      updatedAt
      album {
        id
        name
        companyId
        uri
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        devices {
          nextToken
        }
        assets {
          nextToken
        }
      }
      owner
      asset {
        id
        name
        fileName
        description
        uriId
        type
        mimeType
        companyId
        companyCode
        registrationKey
        isEnabled
        isVisible
        duration
        runningTime
        fileSize
        displayOrder
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
        height
        width
        ratio
        s3FilePath
        s3Bucket
        isTemplate
        templateUrl
        fileLastModified
        owner
        transactionID
        uri
        signedFullSizeUrl
        resizeMode
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        albums {
          nextToken
        }
        devices {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteAlbumAsset = /* GraphQL */ `
  subscription OnDeleteAlbumAsset {
    onDeleteAlbumAsset {
      id
      albumId
      assetId
      createdAt
      updatedAt
      album {
        id
        name
        companyId
        uri
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        devices {
          nextToken
        }
        assets {
          nextToken
        }
      }
      owner
      asset {
        id
        name
        fileName
        description
        uriId
        type
        mimeType
        companyId
        companyCode
        registrationKey
        isEnabled
        isVisible
        duration
        runningTime
        fileSize
        displayOrder
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
        height
        width
        ratio
        s3FilePath
        s3Bucket
        isTemplate
        templateUrl
        fileLastModified
        owner
        transactionID
        uri
        signedFullSizeUrl
        resizeMode
        createdAt
        updatedAt
        company {
          id
          licenseNo
          registrationKey
          env
          companyCode
          services
          isMultiService
          timeZone
          companyName
          address
          domain
          registeredDate
          contactPerson
          maxDevice
          maxCheckInDevice
          queueSettings
          appSettings
          createdAt
          updatedAt
        }
        albums {
          nextToken
        }
        devices {
          nextToken
        }
      }
    }
  }
`;
export const onCreateAsset = /* GraphQL */ `
  subscription OnCreateAsset {
    onCreateAsset {
      id
      name
      fileName
      description
      uriId
      type
      mimeType
      companyId
      companyCode
      registrationKey
      isEnabled
      isVisible
      duration
      runningTime
      fileSize
      displayOrder
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      height
      width
      ratio
      s3FilePath
      s3Bucket
      isTemplate
      templateUrl
      fileLastModified
      owner
      transactionID
      uri
      signedFullSizeUrl
      resizeMode
      createdAt
      updatedAt
      company {
        id
        licenseNo
        registrationKey
        env
        companyCode
        services
        isMultiService
        timeZone
        companyName
        address
        domain
        registeredDate
        contactPerson
        maxDevice
        maxCheckInDevice
        queueSettings
        appSettings
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      albums {
        items {
          id
          albumId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      devices {
        items {
          id
          deviceId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAsset = /* GraphQL */ `
  subscription OnUpdateAsset {
    onUpdateAsset {
      id
      name
      fileName
      description
      uriId
      type
      mimeType
      companyId
      companyCode
      registrationKey
      isEnabled
      isVisible
      duration
      runningTime
      fileSize
      displayOrder
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      height
      width
      ratio
      s3FilePath
      s3Bucket
      isTemplate
      templateUrl
      fileLastModified
      owner
      transactionID
      uri
      signedFullSizeUrl
      resizeMode
      createdAt
      updatedAt
      company {
        id
        licenseNo
        registrationKey
        env
        companyCode
        services
        isMultiService
        timeZone
        companyName
        address
        domain
        registeredDate
        contactPerson
        maxDevice
        maxCheckInDevice
        queueSettings
        appSettings
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      albums {
        items {
          id
          albumId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      devices {
        items {
          id
          deviceId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAsset = /* GraphQL */ `
  subscription OnDeleteAsset {
    onDeleteAsset {
      id
      name
      fileName
      description
      uriId
      type
      mimeType
      companyId
      companyCode
      registrationKey
      isEnabled
      isVisible
      duration
      runningTime
      fileSize
      displayOrder
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      height
      width
      ratio
      s3FilePath
      s3Bucket
      isTemplate
      templateUrl
      fileLastModified
      owner
      transactionID
      uri
      signedFullSizeUrl
      resizeMode
      createdAt
      updatedAt
      company {
        id
        licenseNo
        registrationKey
        env
        companyCode
        services
        isMultiService
        timeZone
        companyName
        address
        domain
        registeredDate
        contactPerson
        maxDevice
        maxCheckInDevice
        queueSettings
        appSettings
        createdAt
        updatedAt
        devices {
          nextToken
        }
      }
      albums {
        items {
          id
          albumId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      devices {
        items {
          id
          deviceId
          assetId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateTicker = /* GraphQL */ `
  subscription OnCreateTicker {
    onCreateTicker {
      id
      message
      companyId
      registrationKey
      fontColor
      backgroundColor
      fontSize
      speed
      isEnabled
      transactionID
      duration
      startDateTime
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateTicker = /* GraphQL */ `
  subscription OnUpdateTicker {
    onUpdateTicker {
      id
      message
      companyId
      registrationKey
      fontColor
      backgroundColor
      fontSize
      speed
      isEnabled
      transactionID
      duration
      startDateTime
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteTicker = /* GraphQL */ `
  subscription OnDeleteTicker {
    onDeleteTicker {
      id
      message
      companyId
      registrationKey
      fontColor
      backgroundColor
      fontSize
      speed
      isEnabled
      transactionID
      duration
      startDateTime
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateDeviceCode = /* GraphQL */ `
  subscription OnCreateDeviceCode {
    onCreateDeviceCode {
      id
      code
      userName
      phone
      email
      companyCode
      companyId
      companyName
      lifeSpanInMins
      updatedAt
      registrationKey
      companyInfo
      createdAt
    }
  }
`;
export const onUpdateDeviceCode = /* GraphQL */ `
  subscription OnUpdateDeviceCode {
    onUpdateDeviceCode {
      id
      code
      userName
      phone
      email
      companyCode
      companyId
      companyName
      lifeSpanInMins
      updatedAt
      registrationKey
      companyInfo
      createdAt
    }
  }
`;
export const onDeleteDeviceCode = /* GraphQL */ `
  subscription OnDeleteDeviceCode {
    onDeleteDeviceCode {
      id
      code
      userName
      phone
      email
      companyCode
      companyId
      companyName
      lifeSpanInMins
      updatedAt
      registrationKey
      companyInfo
      createdAt
    }
  }
`;
export const onCreateWeather = /* GraphQL */ `
  subscription OnCreateWeather {
    onCreateWeather {
      id
      isDateTimeVisible
      isDailyVisible
      isHourlyVisible
      style
      widgetPosition
      gradientColors
      isAnimated
      widgetHeight
      dateTimeFormat
      fontColor
      iconColor
      isEnabled
      transactionID
      companyId
      registrationKey
      openWEatherAPIkey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateWeather = /* GraphQL */ `
  subscription OnUpdateWeather {
    onUpdateWeather {
      id
      isDateTimeVisible
      isDailyVisible
      isHourlyVisible
      style
      widgetPosition
      gradientColors
      isAnimated
      widgetHeight
      dateTimeFormat
      fontColor
      iconColor
      isEnabled
      transactionID
      companyId
      registrationKey
      openWEatherAPIkey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteWeather = /* GraphQL */ `
  subscription OnDeleteWeather {
    onDeleteWeather {
      id
      isDateTimeVisible
      isDailyVisible
      isHourlyVisible
      style
      widgetPosition
      gradientColors
      isAnimated
      widgetHeight
      dateTimeFormat
      fontColor
      iconColor
      isEnabled
      transactionID
      companyId
      registrationKey
      openWEatherAPIkey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateWaitList = /* GraphQL */ `
  subscription OnCreateWaitList {
    onCreateWaitList {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onUpdateWaitList = /* GraphQL */ `
  subscription OnUpdateWaitList {
    onUpdateWaitList {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onDeleteWaitList = /* GraphQL */ `
  subscription OnDeleteWaitList {
    onDeleteWaitList {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
      owner
    }
  }
`;
export const onCreateServedGuest = /* GraphQL */ `
  subscription OnCreateServedGuest {
    onCreateServedGuest {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      delete_me
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
    }
  }
`;
export const onUpdateServedGuest = /* GraphQL */ `
  subscription OnUpdateServedGuest {
    onUpdateServedGuest {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      delete_me
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
    }
  }
`;
export const onDeleteServedGuest = /* GraphQL */ `
  subscription OnDeleteServedGuest {
    onDeleteServedGuest {
      id
      guestId
      channelType
      withNotifConsent
      serviceId
      service
      delete_me
      companyId
      companyCode
      ticketNumber
      ticketId
      dateStamp
      createdAt
      regType
      regValue
      updatedAt
      smsTimestamp
      smsNotifTimeStamp
      isSmsNotifSent
      isAboutToBeServedSmsNotifSent
      fullName
      firstName
      lastName
      reasonForVisit
      company
      messageId
      checkedInType
      receiptHandle
      assignedUser
      assignedUserId
      assignedLocation
      serviceStarted
      serviceEnded
      deviceIpAddress
      serviceDuration
      guestCount
    }
  }
`;
export const onCreateGuestSequenceNumber = /* GraphQL */ `
  subscription OnCreateGuestSequenceNumber {
    onCreateGuestSequenceNumber {
      id
      sequence_number
      waitTime
      waitList
      currentTicketId
      currentTicketNumber
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGuestSequenceNumber = /* GraphQL */ `
  subscription OnUpdateGuestSequenceNumber {
    onUpdateGuestSequenceNumber {
      id
      sequence_number
      waitTime
      waitList
      currentTicketId
      currentTicketNumber
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGuestSequenceNumber = /* GraphQL */ `
  subscription OnDeleteGuestSequenceNumber {
    onDeleteGuestSequenceNumber {
      id
      sequence_number
      waitTime
      waitList
      currentTicketId
      currentTicketNumber
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTextToSpeech = /* GraphQL */ `
  subscription OnCreateTextToSpeech {
    onCreateTextToSpeech {
      id
      companyId
      companyCode
      branchCode
      ticketNumber
      location
      createdAt
      updatedAt
      s3Bucket
      s3Uri
      s3SignedUrl
      s3Filename
      readCount
      audioMode
      speechSpeed
    }
  }
`;
export const onUpdateTextToSpeech = /* GraphQL */ `
  subscription OnUpdateTextToSpeech {
    onUpdateTextToSpeech {
      id
      companyId
      companyCode
      branchCode
      ticketNumber
      location
      createdAt
      updatedAt
      s3Bucket
      s3Uri
      s3SignedUrl
      s3Filename
      readCount
      audioMode
      speechSpeed
    }
  }
`;
export const onDeleteTextToSpeech = /* GraphQL */ `
  subscription OnDeleteTextToSpeech {
    onDeleteTextToSpeech {
      id
      companyId
      companyCode
      branchCode
      ticketNumber
      location
      createdAt
      updatedAt
      s3Bucket
      s3Uri
      s3SignedUrl
      s3Filename
      readCount
      audioMode
      speechSpeed
    }
  }
`;
